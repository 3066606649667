import Icon from "components/Icon"

const CircularWaterDisplay = () => {

  return (


          <div className="relative z-0 *:p-2 grid grid-cols-2 gap-28 grid-rows-2 w-full ">


            <div className="-z-10 *:row-start-1 *:row-end-1 *:col-start-1 *:col-end-1 grid grid-cols-1 grid-rows-1  absolute left-1/2 top-1/4 md:top-1/2 -translate-x-1/2 -translate-y-1/2">
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[8px] outline-blue-100/90 dark:outline-blue-800/90 "></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[8px] outline-red-100/60 dark:outline-red-800/60 "></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[18px] outline-blue-100/80 dark:outline-blue-800/80"></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[18px] outline-red-100/40 dark:outline-red-800/40"></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[30px] outline-blue-100/70 dark:outline-blue-800/70"></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[30px] outline-red-100/30 dark:outline-red-800/30"></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[44px] outline-blue-100/60 dark:outline-blue-800/60"></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[44px] outline-red-100/20 dark:outline-red-800/20"></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[60px] outline-blue-100/50 dark:outline-blue-800/50"></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[78px] outline-blue-100/40 dark:outline-blue-800/40"></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[98px] outline-blue-100/30 dark:outline-blue-800/30"></div>
              <div className="rounded-full aspect-square outline outline-2 outline-offset-[120px] outline-blue-100/20 dark:outline-blue-800/20"></div>
              <div className="p-5 w-fit rounded-full bg-red-100 dark:bg-red-800">
                <Icon
                  name="water-recycle-4-arrows"
                  className="size-24"
                  primaryColor="blue-500"
                  darkSecondaryColor="blue-100"
                  secondaryColor="blue-900"
                />
              </div>
            </div>
            <div className="text-right hidden md:block">
              <h4>Digitalización del patrimonio hidráulico</h4>
              <p className="text-sm">La digitalización de elementos del patrimonio hidráulico, como canales, acequias, norias y otros sistemas de irrigación tradicionales, permite crear una representación digital precisa de estos elementos. Esta digitalización ayuda a preservar y difundir el conocimiento sobre estas estructuras históricas y facilita su gestión y mantenimiento.</p>
            </div>
            <div className="hidden md:block">
              <h4>Promoción del turismo cultural</h4>
              <p className="text-sm">La economía circular digital basada en el patrimonio hidráulico impulsa el desarrollo del turismo cultural en la Huerta de Valencia. La creación de experiencias turísticas basadas en el patrimonio hidráulico digitalizado atrae a visitantes interesados en explorar la riqueza histórica y cultural de la región, generando beneficios económicos y fomentando el respeto y la preservación del patrimonio.</p>
            </div>
            <div className="text-right hidden md:block">
              <h4>Creación de valor digital</h4>
              <p className="text-sm">La digitalización del patrimonio hidráulico genera un valor digital que puede ser utilizado en diferentes formas. Esta representación digital puede ser utilizada para la planificación y gestión de los recursos hídricos, la promoción del turismo cultural y la educación sobre la importancia del patrimonio hidráulico en la Huerta de Valencia.</p>
            </div>
            <div className="hidden md:block">
              <h4>Participación y colaboración ciudadana</h4>
              <p className="text-sm">La economía circular digital basada en el patrimonio hidráulico promueve la participación ciudadana y la colaboración en la gestión del agua. La creación de plataformas digitales y herramientas interactivas permite a los ciudadanos contribuir con información, compartir conocimientos y participar en iniciativas de conservación y uso sostenible del agua.</p>
            </div>

            <div className="md:hidden row-start-2 snap-x flex overflow-x-scroll snap-mandatory col-span-full gap-8 *:min-w-full flex-row">
            <div className="snap-start">
              <h4>Digitalización del patrimonio hidráulico</h4>
              <p className="text-sm">La digitalización de elementos del patrimonio hidráulico, como canales, acequias, norias y otros sistemas de irrigación tradicionales, permite crear una representación digital precisa de estos elementos. Esta digitalización ayuda a preservar y difundir el conocimiento sobre estas estructuras históricas y facilita su gestión y mantenimiento.</p>
            </div>
            <div className="snap-start">
              <h4>Promoción del turismo cultural</h4>
              <p className="text-sm">La economía circular digital basada en el patrimonio hidráulico impulsa el desarrollo del turismo cultural en la Huerta de Valencia. La creación de experiencias turísticas basadas en el patrimonio hidráulico digitalizado atrae a visitantes interesados en explorar la riqueza histórica y cultural de la región, generando beneficios económicos y fomentando el respeto y la preservación del patrimonio.</p>
            </div>
            <div className="snap-start">
              <h4>Creación de valor digital</h4>
              <p className="text-sm">La digitalización del patrimonio hidráulico genera un valor digital que puede ser utilizado en diferentes formas. Esta representación digital puede ser utilizada para la planificación y gestión de los recursos hídricos, la promoción del turismo cultural y la educación sobre la importancia del patrimonio hidráulico en la Huerta de Valencia.</p>
            </div>
            <div className="snap-start">
              <h4>Participación y colaboración ciudadana</h4>
              <p className="text-sm">La economía circular digital basada en el patrimonio hidráulico promueve la participación ciudadana y la colaboración en la gestión del agua. La creación de plataformas digitales y herramientas interactivas permite a los ciudadanos contribuir con información, compartir conocimientos y participar en iniciativas de conservación y uso sostenible del agua.</p>
            </div>
            </div>
          </div>

          
  )
}

export default CircularWaterDisplay