import CardItemWithIcon from "components/molecules/CardItemWithIcon";
import { CardItemType } from "lib/types";
import Contacts from "components/sections/Contacts";
import SensorTechnology from "./SensorTechnology";
import DesktopSwitcher from "./DesktopSwitcher";
import PhoneSwitcher from "./PhoneSwitcher";

const Technology = () => {
  const technologyCards: CardItemType[] = [
    {
      title: "Sistemas de telemetría y control en tiempo real",
      content:
        "Implementamos sistemas de telemetría y control en tiempo real para monitorear y gestionar de manera eficiente los recursos hídricos. Estos sistemas nos permiten recopilar datos precisos sobre el flujo de agua, los niveles de humedad del suelo y otros parámetros relevantes. Utilizando esta información, podemos ajustar las operaciones de riego y garantizar un uso óptimo del agua.",
      icon: "real-time-sensor",
    },
    {
      title: "Sensores y dispositivos inteligentes",
      content:
        "Empleamos una variedad de sensores y dispositivos inteligentes para monitorear y recopilar datos en tiempo real sobre la calidad del agua, la temperatura, la humedad y otros indicadores relevantes. Estos dispositivos nos ayudan a detectar problemas potenciales, identificar tendencias y tomar decisiones informadas para garantizar la calidad del agua y la eficiencia en su uso.",
      icon: "water-technologies-smart",
    },
    {
      title: "Plataformas de gestión de datos",
      content:
        "Utilizamos plataformas de gestión de datos para almacenar, analizar y visualizar la información recopilada por nuestros sistemas de monitoreo. Estas plataformas nos brindan una visión completa y en tiempo real de los datos, lo que nos permite tomar decisiones basadas en evidencia y optimizar nuestras operaciones.",
      icon: "digital-water",
    },
    {
      title: "Modelos de predicción y análisis avanzado",
      content:
        "Aplicamos modelos de predicción y análisis avanzado para prever la demanda de agua, identificar patrones de uso y optimizar la planificación del riego. Estos modelos nos ayudan a optimizar el uso del agua y a garantizar una distribución equitativa y eficiente de los recursos hídricos en la Huerta de Valencia.",
      icon: "predictive-system",
    },
    {
      title: "Tecnología de información geográfica (GIS)",
      content:
        "Utilizamos tecnología de información geográfica para mapear y visualizar la distribución de los recursos hídricos, los sistemas de riego y otros elementos relevantes en la Huerta de Valencia. Esto nos permite tener una comprensión completa de la infraestructura hidráulica y optimizar su funcionamiento.",
      icon: "public-education",
    },
  ];

  return (
    <div>
      <section className="h-screen text-white bg-gray-900 dark:bg-gray-950 overflow-x-clip flex w-full flex-col md:flex-row relative  py-20">
        <div className="w-full justify-start md:justify-center z-10 flex h-full gap-y-10 flex-col p-4 md:pr-96 xl:pr-64 2xl:pr-32">
          <div className="flex-col-reverse justify-start items-start gap-y-2 flex">
            <h1 className="">
              Innovación y Tecnología para la{" "}
              <span className="inline-flex relative z-0">
                Gestión del Agua
                <div className="absolute -z-10 w-full h-3 bottom-2 bg-gray-500" />
              </span>
            </h1>
            <h2 className=" text-gray-600 text-xl font-bold lg:text-2xl uppercase leading-7">
              Tecnología
            </h2>
          </div>
          <ul className="text-lg *:bg-gray-900/50 dark:*:bg-gray-950/50 *:backdrop-blur-md *:p-2 *:rounded-md grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-4 md:gap-6">
            <li className="flex flex-col">
              <span className="font-semibold text-lg">
                Sistemas de Monitoreo en Tiempo Real
              </span>
              <span className="text-base">
                Control preciso del flujo y calidad del agua
              </span>
            </li>
            <li className="flex flex-col">
              <span className="font-semibold text-lg">
                Sensores y Dispositivos Inteligentes
              </span>
              <span className="text-base">
                Tecnologías de vanguardia para la conservación del agua
              </span>
            </li>
            <li className="flex flex-col">
              <span className="font-semibold text-lg">
                Plataformas de Gestión de Datos
              </span>
              <span className="text-base">
                Herramientas para decisiones informadas y optimización de
                recursos
              </span>
            </li>
          </ul>
        </div>

        <span className="w-full h-auto right-0 z-0 flex justify-center bottom-0 absolute">
          {/* <BottomDivider className="w-full max-h-48 md:max-h-full transition-[max-height] min-w-max h-full" /> */}
          <svg
            className="w-full max-h-32 sm:max-h-48 md:max-h-full transition-[max-height] min-w-max h-full"
            width="1440"
            height="379"
            viewBox="0 0 1440 379"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M921.24 51.1016C921.24 47.3756 924.265 44.3516 927.99 44.3516C931.715 44.3516 934.74 47.3756 934.74 51.1016C934.74 54.8266 931.715 57.8516 927.99 57.8516C924.265 57.8516 921.24 54.8266 921.24 51.1016Z"
              className="fill-gray-700"
            />
            <path
              d="M863.99 378.583V307.102H735.76V146.872L927.99 148.243V51.1016"
              className="stroke-gray-700"
              stroke-width="6"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M287.991 281.852C284.266 281.852 281.241 278.827 281.241 275.102C281.241 271.376 284.266 268.352 287.991 268.352C291.716 268.352 294.741 271.376 294.741 275.102C294.741 278.827 291.716 281.852 287.991 281.852Z"
              className="fill-gray-700"
            />
            <path
              d="M479.991 378.583V275.102H287.991"
              className="stroke-gray-700"
              stroke-width="6"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1247.99 237.854C1251.72 237.854 1254.74 234.831 1254.74 231.104C1254.74 227.376 1251.72 224.354 1247.99 224.354C1244.26 224.354 1241.24 227.376 1241.24 231.104C1241.24 234.831 1244.26 237.854 1247.99 237.854Z"
              className="fill-gray-700"
            />
            <path
              d="M1183.99 378.584V231.103H1247.99"
              className="stroke-gray-700"
              stroke-width="6"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1049.25 19.1016C1049.25 15.3756 1052.28 12.3516 1056 12.3516C1059.72 12.3516 1062.75 15.3756 1062.75 19.1016C1062.75 22.8266 1059.72 25.8516 1056 25.8516C1052.28 25.8516 1049.25 22.8266 1049.25 19.1016Z"
              className="fill-gray-700"
            />
            <path
              d="M1056 378.583V19.1016"
              className="stroke-gray-700"
              stroke-width="6"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1439.57 363.109H0.000488281V375.109H1439.57V363.109Z"
              className="fill-gray-400 dark:fill-gray-800"
            />
            <path
              d="M1439.57 351.109H0.000488281V363.109H1439.57V351.109Z"
              className="fill-gray-700"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M767.99 285.229C762.402 285.229 757.865 280.692 757.865 275.104C757.865 269.516 762.402 264.979 767.99 264.979C773.578 264.979 778.115 269.516 778.115 275.104C778.115 280.692 773.578 285.229 767.99 285.229Z"
              className="fill-gray-400 dark:fill-gray-800"
            />
            <path
              d="M927.99 378.584V275.103H767.99"
              className="stroke-gray-400 dark:stroke-gray-800"
              stroke-width="9"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M575.991 317.229C570.403 317.229 565.866 312.692 565.866 307.104C565.866 301.516 570.403 296.979 575.991 296.979C581.579 296.979 586.116 301.516 586.116 307.104C586.116 312.692 581.579 317.229 575.991 317.229Z"
              className="fill-gray-400 dark:fill-gray-800"
            />
            <path
              d="M639.991 378.584V307.103H575.991"
              className="stroke-gray-400 dark:stroke-gray-800"
              stroke-width="9"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M117.866 275.332C117.866 269.744 122.403 265.207 127.991 265.207C133.579 265.207 138.116 269.744 138.116 275.332C138.116 280.92 133.579 285.457 127.991 285.457C122.403 285.457 117.866 280.92 117.866 275.332Z"
              className="fill-gray-400 dark:fill-gray-800"
            />
            <path
              d="M415.991 378.582V307.101H127.991V275.331"
              className="stroke-gray-400 dark:stroke-gray-800"
              stroke-width="9"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M965.865 83.1035C965.865 77.5155 970.402 72.9785 975.99 72.9785C981.578 72.9785 986.115 77.5155 986.115 83.1035C986.115 88.6915 981.578 93.2285 975.99 93.2285C970.402 93.2285 965.865 88.6915 965.865 83.1035Z"
              className="fill-gray-400 dark:fill-gray-800"
            />
            <path
              d="M1023.99 378.584V179.103H975.99V83.1025"
              className="stroke-gray-400 dark:stroke-gray-800"
              stroke-width="9"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1407.99 125.229C1413.58 125.229 1418.12 120.695 1418.12 115.104C1418.12 109.512 1413.58 104.979 1407.99 104.979C1402.4 104.979 1397.87 109.512 1397.87 115.104C1397.87 120.695 1402.4 125.229 1407.99 125.229Z"
              className="fill-gray-400 dark:fill-gray-800"
            />
            <path
              d="M1407.99 378.584V307.103H1359.99V115.103H1407.99"
              className="stroke-gray-400 dark:stroke-gray-800"
              stroke-width="9"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1298.5 147.102C1298.5 139.651 1304.55 133.602 1312 133.602C1319.45 133.602 1325.5 139.651 1325.5 147.102C1325.5 154.553 1319.45 160.602 1312 160.602C1304.55 160.602 1298.5 154.553 1298.5 147.102Z"
              className="fill-gray-100 dark:fill-gray-900"
            />
            <path
              d="M1248 378.583V275.102H1312V147.102"
              className="stroke-gray-100 dark:stroke-gray-900"
              stroke-width="12"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M658.266 179.102C658.266 171.651 664.315 165.602 671.766 165.602C679.217 165.602 685.266 171.651 685.266 179.102C685.266 186.553 679.217 192.602 671.766 192.602C664.315 192.602 658.266 186.553 658.266 179.102Z"
              className="fill-gray-100 dark:fill-gray-900"
            />
            <path
              d="M671.766 378.583V179.102"
              className="stroke-gray-100 dark:stroke-gray-900"
              stroke-width="12"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1376 64.2559C1383.45 64.2559 1389.5 58.2117 1389.5 50.7559C1389.5 43.3 1383.45 37.2559 1376 37.2559C1368.54 37.2559 1362.5 43.3 1362.5 50.7559C1362.5 58.2117 1368.54 64.2559 1376 64.2559Z"
              className="fill-gray-100 dark:fill-gray-900"
            />
            <path
              d="M1120 378.583V186.583H1184V50.7559H1376"
              className="stroke-gray-100 dark:stroke-gray-900"
              stroke-width="12"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M799.99 224.602C792.539 224.602 786.49 218.553 786.49 211.102C786.49 203.651 792.539 197.602 799.99 197.602C807.441 197.602 813.49 203.651 813.49 211.102C813.49 218.553 807.441 224.602 799.99 224.602Z"
              className="fill-gray-100 dark:fill-gray-900"
            />
            <path
              d="M991.99 378.583V211.102H799.99"
              className="stroke-gray-100 dark:stroke-gray-900"
              stroke-width="12"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M50.4912 211.102C50.4912 203.651 56.5402 197.602 63.9912 197.602C71.4422 197.602 77.4912 203.651 77.4912 211.102C77.4912 218.553 71.4422 224.602 63.9912 224.602C56.5402 224.602 50.4912 218.553 50.4912 211.102Z"
              className="fill-gray-100 dark:fill-gray-900"
            />
            <path
              d="M351.991 378.583V339.102H63.9912V211.102"
              className="stroke-gray-100 dark:stroke-gray-900"
              stroke-width="12"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M621.491 243.102C621.491 250.553 615.442 256.602 607.991 256.602C600.54 256.602 594.491 250.553 594.491 243.102C594.491 235.651 600.54 229.602 607.991 229.602C615.442 229.602 621.491 235.651 621.491 243.102Z"
              className="fill-gray-100 dark:fill-gray-900"
            />
            <path
              d="M543.991 378.583V207.102H607.991V243.102"
              className="stroke-gray-100 dark:stroke-gray-900"
              stroke-width="12"
              stroke-miterlimit="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1439.57 375.109H0.000488281V387.109H1439.57V375.109Z"
              className="fill-gray-100 dark:fill-gray-900"
            />
          </svg>
        </span>
      </section>

      <section className=" bg-gray-100 dark:bg-gray-900">
        <div>
          <h2>Campañas de Concienciación sobre el Valor del Agua</h2>
          <p>
            Como parte del compromiso de Hortatech con el impacto social, se
            llevan a cabo campañas de concienciación sobre el valor del agua en
            la Huerta de Valencia. Estas campañas tienen como objetivo
            sensibilizar a la sociedad sobre la importancia de este recurso
            vital y promover prácticas responsables en su uso. Algunos aspectos
            clave de estas campañas incluyen:
          </p>
          <div className="grid grid-cols-2 gap-4">
            {technologyCards.map((card) => (
              <CardItemWithIcon
                primaryColor="green-500"
                secondaryColor="green-900"
                key={card.title}
                {...card}
              />
            ))}
          </div>
          <p>
            El objetivo principal de estas campañas de concienciación es
            fomentar una cultura del agua responsable, donde se valore y se
            utilice de manera consciente y sostenible. A través de la educación
            y la participación ciudadana, Hortatech busca generar un cambio de
            actitud hacia el uso del agua y promover una gestión responsable que
            garantice su disponibilidad a largo plazo.
          </p>
        </div>
      </section>
      <section className="bg-gray-100 dark:bg-black">
        <div>
          <h2>Tecnologías de sensores basadas en fibra óptica</h2>
          <p>
            Las tecnologías de sensores basadas en fibra óptica ofrecen diversas
            soluciones para la medición de variables en el agua. Estas
            tecnologías se utilizan en Hortatech para monitorear y garantizar la
            calidad del agua en la Huerta de Valencia. Algunas de estas
            tecnologías incluyen:
          </p>
          <SensorTechnology />
          <p>
            Estas tecnologías de sensores basadas en fibra óptica son
            herramientas valiosas para el monitoreo y control de la calidad del
            agua en la Huerta de Valencia. Proporcionan mediciones precisas y en
            tiempo real de variables importantes, lo que facilita la toma de
            decisiones informadas y la implementación de estrategias efectivas
            para la gestión sostenible del agua.
          </p>
        </div>
      </section>
      <section className="bg-gray-900 pb-4 text-white">
        <div>
          <h2>Panel de control para la gestión digital del ciclo del agua</h2>
          <p>
            Un panel de control para la gestión digital del ciclo del agua es
            una herramienta centralizada que permite monitorear y controlar de
            manera eficiente los diferentes aspectos del ciclo del agua, desde
            la captación hasta el tratamiento y la distribución. Este panel de
            control utiliza tecnologías digitales y sistemas de información para
            recopilar, procesar y visualizar datos relevantes en tiempo real, lo
            que facilita la toma de decisiones informadas y la optimización de
            los recursos hídricos.
          </p>

          <DesktopSwitcher />
        </div>
      </section>
      <section className="bg-gray-900 text-white">
        <div className="">
          <h2>
            Aplicación de gestion digital del riego de la huerta de Valencia
          </h2>

          <p>
            La aplicación digital de gestión del riego de la Huerta de Valencia
            ofrece a los usuarios un acceso rápido y sencillo a todas las
            funcionalidades necesarias para gestionar el riego de manera
            eficiente. Con esta aplicación, los usuarios pueden administrar los
            tiempos de riego, realizar planificaciones simples y realizar un
            registro de control cuando el agua llega a la huerta. 
          </p>

          <PhoneSwitcher />
        </div>
      </section>
      <section>
        <Contacts />
      </section>
    </div>
  );
};

export default Technology;
