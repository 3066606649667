import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Only scroll to the top if there is no hash value, indicating a pathname change without an anchor link
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);  // Depend only on pathname

  return null;  // This component does not render anything
}

export default ScrollToTop;
