import React, { useEffect, useState } from "react";
import { ReactComponent as Automation } from "assets/icons/automation.svg";
import { ReactComponent as Automation2 } from "assets/icons/automation-2.svg";
import { ReactComponent as Collaboration } from "assets/icons/collaboration.svg";
import { ReactComponent as DataManagement } from "assets/icons/data-management.svg";
import { ReactComponent as DataManagementWide } from "assets/icons/data-management-wide.svg";
import { ReactComponent as DigitalPatrimony } from "assets/icons/digital-patrimony.svg";
import { ReactComponent as DigitalWater } from "assets/icons/digital-water.svg";
import { ReactComponent as GlobalWarming } from "assets/icons/global-warming.svg";
import { ReactComponent as Infrastructure } from "assets/icons/infrastructure.svg";
import { ReactComponent as Infrastructure2 } from "assets/icons/infrastructure-2.svg";
import { ReactComponent as LoveWorld } from "assets/icons/love-world.svg";
import { ReactComponent as MeasureTemperature } from "assets/icons/measure-temperature.svg";
import { ReactComponent as MeasureWaterFlow } from "assets/icons/measure-water-flow.svg";
import { ReactComponent as PredictiveSystem } from "assets/icons/predictive-system.svg";
import { ReactComponent as PreserveNature } from "assets/icons/preserve-nature.svg";
import { ReactComponent as ProtectBiodiversity } from "assets/icons/protect-biodiversity.svg";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";

import { ReactComponent as PublicEducationSquare } from "assets/icons/public-education-square.svg";
import { ReactComponent as PublicEducation } from "assets/icons/public-education.svg";
import { ReactComponent as PublicEducationWater } from "assets/icons/public-education-water.svg";

import { ReactComponent as RealTimeSensor } from "assets/icons/real-time-sensor.svg";
import { ReactComponent as RestorationEcosystem } from "assets/icons/restoration-ecosystem.svg";
import { ReactComponent as Sensor } from "assets/icons/sensor.svg";
import { ReactComponent as SensorZoom } from "assets/icons/sensor-zoom.svg";
import { ReactComponent as SocialConscience } from "assets/icons/social-conscience.svg";
import { ReactComponent as SocialFocus } from "assets/icons/social-focus.svg";
import { ReactComponent as WaterPreserve } from "assets/icons/water-preserve.svg";
import { ReactComponent as WaterRecycle } from "assets/icons/water-recycle.svg";
import { ReactComponent as WaterRecycle4Arrows } from "assets/icons/water-recycle-4-arrows.svg";
import { ReactComponent as WaterTechnologiesSmart } from "assets/icons/water-technologies-smart.svg";
import { ReactComponent as WaterTechnologies } from "assets/icons/water-technologies.svg";
import { ReactComponent as TimeMeasurementSimple } from "assets/icons/time-measurement-simple.svg";
import { ReactComponent as TimeMeasurementComplex } from "assets/icons/time-measurement-complex.svg";
import { ReactComponent as MaintainPatrimony } from "assets/icons/maintain-patrimony.svg";
import { ReactComponent as SpreadInformation } from "assets/icons/spread-information.svg";
import { ReactComponent as SocialGrowth } from "assets/icons/social-growth.svg";
import { ReactComponent as Dashboard } from "assets/icons/dashboard.svg";
import { ReactComponent as WorkTogether } from "assets/icons/work-together.svg";

import { ReactComponent as TechNewAllNetwork } from "assets/icons/tech-new-all-network.svg";
import { ReactComponent as TechNewCorrect } from "assets/icons/tech-new-correct.svg";
import { ReactComponent as TechNewDigital } from "assets/icons/tech-new-digital.svg";
import { ReactComponent as TechNewManual } from "assets/icons/tech-new-manual.svg";
import { ReactComponent as TechNewPoint } from "assets/icons/tech-new-point.svg";
import { ReactComponent as TechNewPredict } from "assets/icons/tech-new-predict.svg";
import { ReactComponent as TechNewTransparent } from "assets/icons/tech-new-transparent.svg";
import { ReactComponent as TechNewHidden } from "assets/icons/tech-new-hidden.svg";

import { ReactComponent as Investigation } from "assets/icons/investigate.svg";
import { ReactComponent as Diffusion } from "assets/icons/diffusion.svg";
import { ReactComponent as GlobalAccessibility } from "assets/icons/global-accesibility.svg";
import { ReactComponent as Conscienciation } from "assets/icons/concienciacion.svg";
import { getColor } from "../lib/tailwindColorsResolver";

import { ReactComponent as ProhibitionSign } from "assets/icons/prohibition-sign.svg"

const iconMap: { [key: string]: React.FC<React.SVGProps<SVGSVGElement>> } = {
  automation: Automation,
  "digital-water": DigitalWater,
  "global-warming": GlobalWarming,
  infrastructure: Infrastructure,
  "infrastructure-2": Infrastructure2,
  "love-world": LoveWorld,
  "measure-temperature": MeasureTemperature,
  "measure-water-flow": MeasureWaterFlow,
  "predictive-system": PredictiveSystem,
  "preserve-nature": PreserveNature,
  "protect-biodiversity": ProtectBiodiversity,
  "public-education-square": PublicEducationSquare,
  "real-time-sensor": RealTimeSensor,
  "restoration-ecosystem": RestorationEcosystem,
  sensor: Sensor,
  "sensor-zoom": SensorZoom,
  "social-conscience": SocialConscience,
  "social-focus": SocialFocus,
  "water-preserve": WaterPreserve,
  "water-recycle": WaterRecycle,
  "water-recycle-4-arrows": WaterRecycle4Arrows,
  "water-technologies-smart": WaterTechnologiesSmart,
  "water-technologies": WaterTechnologies,
  "data-management": DataManagement,
  "data-management-wide": DataManagementWide,
  "digital-patrimony": DigitalPatrimony,
  collaboration: Collaboration,
  "automation-2": Automation2,
  "time-measurement-simple": TimeMeasurementSimple,
  "time-measurement-complex": TimeMeasurementComplex,
  "maintain-patrimony": MaintainPatrimony,
  "spread-information": SpreadInformation,
  "social-growth": SocialGrowth,
  "public-education": PublicEducation,
  "public-education-water": PublicEducationWater,
  "work-together": WorkTogether,
  dashboard: Dashboard,
  arrow: Arrow,
  "tech-new-all-network": TechNewAllNetwork,
  "tech-new-correct": TechNewCorrect,
  "tech-new-digital": TechNewDigital,
  "tech-new-manual": TechNewManual,
  "tech-new-point": TechNewPoint,
  "tech-new-predict": TechNewPredict,
  "tech-new-transparent": TechNewTransparent,
  "tech-new-hidden": TechNewHidden,
  "prohibition-sign": ProhibitionSign,
  "investigation": Investigation,
  diffusion: Diffusion,
  "global-accessibility": GlobalAccessibility,
  conscienciation: Conscienciation,
};

interface IconProps {
  name: string;
  className?: string;
  primaryColor?: string;
  secondaryColor?: string;
  darkSecondaryColor?: string;
}
const isDarkMode = () => document.documentElement.classList.contains("dark");

const Icon: React.FC<IconProps> = ({
  name,
  className,
  primaryColor = "",
  secondaryColor = "",
  darkSecondaryColor = "",
}) => {
  const SvgIcon = iconMap[name] || (() => null);

  const [primaryColorValue, setPrimaryColorValue] = useState("currentColor");
  const [secondaryColorValue, setSecondaryColorValue] =
    useState("currentColor");

  useEffect(() => {
    console.log(
      "trying to resolve primary color: ",
      name,
      primaryColor,
      secondaryColor,
      darkSecondaryColor,
      isDarkMode()
    );

    const updateColors = () => {
      const secondaryColorToUse =
        isDarkMode() && darkSecondaryColor
          ? getColor(darkSecondaryColor)
          : getColor(secondaryColor);
      setSecondaryColorValue(secondaryColorToUse);
    };
    if (primaryColor) {
      const color = getColor(primaryColor);
      setPrimaryColorValue(color);

      updateColors();
      console.log("secondary color: ", secondaryColor);
      // Recheck when system color scheme changes
      window.addEventListener("classChange", updateColors);

      return () => {
        window.removeEventListener("classChange", updateColors);
      };
    }
  }, [primaryColor, secondaryColor, darkSecondaryColor, name]);

  return (
    <SvgIcon
      className={className}
      style={
        {
          color: primaryColorValue,
          "--secondary-color": secondaryColorValue,
        } as React.CSSProperties
      }
    />
  );
};

export default Icon;
