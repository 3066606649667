const PhoneComponent = ({ image, alt, frameColors, containerColors }: { image: string; alt: string, frameColors?: string, containerColors?: string }) => {

  // let containerColors = "border-gray-800 dark:border-gray-800 ";
  // let frameColors = "bg-gray-800";

  return (
    <div className={`${containerColors ? containerColors : "border-gray-800"} ${frameColors ? frameColors : "bg-gray-800 dark:bg-gray-800"} relative mx-auto   border-[12px] transition-all md:border-[14px] rounded-[2.5rem] h-[480px] w-[240px] md:h-[600px] md:w-[300px] shadow-xl"`}>
      <div className={`${frameColors ? frameColors : "bg-gray-800 dark:bg-gray-800"} transition-all w-[118px] h-[15px] md:w-[148px] md:h-[18px]  top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute`}></div>
      <div className={`${frameColors ? frameColors : "bg-gray-800 dark:bg-gray-800"} transition-all h-[37px] w-[3px] md:h-[46px] md:w-[3px]  absolute -start-[15px] md:-start-[17px] top-[100px] md:top-[124px] rounded-s-lg`}></div>
      <div className={`${frameColors ? frameColors : "bg-gray-800 dark:bg-gray-800"} transition-all h-[37px] w-[3px] md:h-[46px] md:w-[3px]  absolute -start-[15px] md:-start-[17px] top-[142px] md:top-[178px] rounded-s-lg`}></div>
      <div className={`${frameColors ? frameColors : "bg-gray-800 dark:bg-gray-800"} transition-all h-[51px] w-[3px] md:h-[64px] md:w-[3px]  absolute -end-[15px] md:-end-[17px] top-[142px] rounded-e-lg`}></div>
      <div className={`${frameColors ? frameColors : "bg-gray-800 dark:bg-gray-800"} transition-all rounded-[1.5rem] overflow-hidden w-[218px] h-[456px] md:w-[272px] md:h-[572px] `}>
        <img
          src={image}
          alt={alt}
          className="transition-all rounded-t-lg w-[218px] md:w-[272px] h-[456px] md:h-[572px]"
        />
      </div>
    </div>
  );
};

export default PhoneComponent;
