import * as React from "react"
import { cn } from "lib/utils"

export interface ImageFrameProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    size?: "sm" | "md" | "lg" | undefined;
}

const ImageFrame = React.forwardRef<HTMLImageElement, ImageFrameProps>(
    ({ className, src, alt, size = "md", ...props }, ref) => {
        return (
            <img
                src={src}
                alt={alt}
                className={cn("w-auto h-full object-right object-cover ",
                    size === "sm" && "rounded-lg md:rounded-xl shadow-md lg:shadow-lg",
                    size === "md" && "md:rounded-[2rem] rounded-xl shadow-lg lg:shadow-2xl",
                    size === "lg" && "rounded-2xl md:rounded-[3rem] shadow-xl lg:shadow-3xl",
                    className
                )}
                ref={ref}
                {...props}
            />
        );
    }
);

ImageFrame.displayName = "ImageFrame"

export { ImageFrame };