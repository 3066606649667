// src/lib/tailwindColorsResolver.js
const colors = require('./tailwindColors');

const getColor = (colorName) => {
  console.log("colorName", colorName)
  const colorParts = colorName.split('-');
  let color = colors;

  for (let part of colorParts) {
    if (color[part]) {
      color = color[part];
    } else {
      color = null;
      break;
    }
  }

  // If color is an object, return the DEFAULT value
  if (typeof color === 'object' && color !== null) {
    return color.DEFAULT || 'currentColor';
  }

  return color || 'currentColor';
};

module.exports = { getColor };
