import CardItemWithIcon from "components/molecules/CardItemWithIcon";

export default function FourPointShowCase({
  image,
  items,
  variant = "default"
}: {
  image?: string;
  items: { icon: string; title: string; content: string }[];
  // children?: ReactNode,
  variant?: "default" | "outline-card"
}) {
  return (
    <div className={image ? "relative md:grid flex lg:grid-cols-[8rem_minmax(0,1fr)] md:grid-rows-1 lg:items-center flex-col lg:flex-row gap-10 md:gap-20" : "relative flex lg:items-center flex-col overflow-hidden gap-10"}>
      {/* <div className="lg:absolute lg:right-[47%] lg:top-20">
        {children}
      </div> */}
      {/* TODO: Use this as a reference for overflowing images to the side, it's sort of a css hack */}
      {image && <div className="flex w-full h-full justify-end lg:mb-0 my-4 translate-x-12 scale-125 lg:translate-x-0 md:scale-110 lg:scale-100">
        <div className="w-fit flex h-full lg:aspect-[2/1] ">
          <img
            src={image}
            alt="Showcase image"
            className="w-auto h-full md:rounded-r-3xl object-right object-cover rounded-xl shadow-lg lg:shadow-2xl"
          />
        </div>
      </div>}
      <div className="flex flex-col lg:py-16 gap-4 md:gap-6 mt-10">
        <div className="md:grid md:grid-cols-2 md:grid-rows-2 flex-1 mx-2 lg:mx-0 flex flex-col gap-4 md:gap-8">
          {items.map((i) => (
            variant === "default" ? <CardItemWithIcon title={i.title} content={i.content} icon={i.icon} boxClass="p-4 shadow-none" />
              : <CardItemWithIcon img={i.icon} boxClass="dark:bg-transparent shadow-none rounded-none p-0 px-5 lg:border-l-0 border-l-2 border-l-blue-600 bg-transparent" title={i.title} content={i.content} icon={i.icon} />
          ))}
        </div>
      </div>
    </div>
  );
}
