import { CardItemType } from "lib/types";
import Icon from "components/Icon";
import { cn } from "lib/utils";

const CardItemWithIcon: React.FC<CardItemType> = ({
  icon = "",
  title,
  content,
  boxClass = "",
  iconClass = "",
  primaryColor = "blue-500",
  secondaryColor = "blue-900",
  darkSecondaryColor = "blue-100",
  children,
}) => {
  return (
    <div
      className={cn(
        "shadow-[0_2px_4px_-1px,_0_4px_8px_-2px] shadow-red-800/5 flex flex-col w-full gap-y-3 rounded-xl dark:text-white dark:bg-gray-950 bg-white p-8",
        boxClass
      )}>
        {icon && 
      <div className={` h-12 w-12 ${iconClass}`}>
        <Icon
          name={icon}
        primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    darkSecondaryColor={darkSecondaryColor}
  className={`aspect-square h-full`}
/>
      </div>
}
      <h4 className="">{title}</h4>
      <p className="">{content}</p>
      {children}
    </div>
  );
};

export default CardItemWithIcon;
