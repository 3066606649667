import { ReactNode, CSSProperties, useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function ObservableSection({ onVisibilityChange, children, threshold = 0.2, className, id, style = {} }: { onVisibilityChange: (inView: boolean, id: string) => void, children: ReactNode, threshold?: number, className?: string, id: string, style?: CSSProperties }) {
	const { ref, inView } = useInView({
		threshold: threshold,
	});

	useEffect(() => {
		onVisibilityChange(false, id);
	})

	useEffect(() => {
		onVisibilityChange(inView, id);
	}, [inView])

	return (
		<section style={style} ref={ref} className={className} id={id}>
			{children}
		</section>
	)
}

// import React, { ReactNode, CSSProperties, useEffect, useRef, useCallback } from "react";
// import { useInView } from "react-intersection-observer";

// interface ObservableSectionProps {
//   onVisibilityChange: (inView: boolean, id: string) => void;
//   onScroll?: (scrollTop: number, height: number) => void;
//   children: ReactNode;
//   threshold?: number;
//   className?: string;
//   id: string;
//   style?: CSSProperties;
// }

// const ObservableSection: React.FC<ObservableSectionProps> = ({
//   onVisibilityChange,
//   onScroll,
//   children,
//   threshold = 0.2,
//   className,
//   id,
//   style = {}
// }) => {
//   const { ref: inViewRef, inView } = useInView({
//     threshold,
//   });

//   const sectionRef = useRef<HTMLElement | null>(null);

//   const setRefs = useCallback((node: HTMLElement | null) => {
//     inViewRef(node);
//     sectionRef.current = node;
//   }, [inViewRef]);

//   useEffect(() => {
//     onVisibilityChange(false, id);
//   }, [id, onVisibilityChange]);

//   useEffect(() => {
//     onVisibilityChange(inView, id);
//   }, [inView, id, onVisibilityChange]);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (sectionRef.current && onScroll) {
//         const { scrollTop, scrollHeight, clientHeight } = sectionRef.current;
// 				console.log("scrollTop", scrollTop, "scrollHeight", scrollHeight, "clientHeight", clientHeight)
//         onScroll(scrollTop, scrollHeight - clientHeight);
//       }
//     };
// 		// console.log("handleScroll", handleScroll)
// 		console.log("sectionRef", sectionRef.current)
//     const sectionElement = sectionRef.current;
//     if (sectionElement) {
//       sectionElement.addEventListener("scroll", handleScroll);
//     }

//     return () => {
//       if (sectionElement) {
//         sectionElement.removeEventListener("scroll", handleScroll);
//       }
//     };
//   }, [onScroll]);

//   return (
//     <section ref={setRefs} style={style} className={className} id={id}>
//       {children}
//     </section>
//   );
// };

// export default ObservableSection;
