import React, { useState } from "react";

import { Input } from "components/ui/input"
import { Label } from "components/ui/label"

interface InputWithLabelProps {
  type: string;
  name: string;
  label?: string;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // isTall?: boolean;
  error?: string;
}

export function InputWithLabel({ type, name, label = "", placeholder, value = "", onChange, error }: InputWithLabelProps) {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e); // Propagate the change event up if an onChange handler is provided
    }
  };

  return (
    <div className=" w-full items-start gap-2">
      {label !== "" && <Label htmlFor={name}>{label}</Label>}
      <Input 
      className="w-full" 
      name={name} 
      type={type} 
      id={name} 
      placeholder={placeholder || ""} 
      value={inputValue}
      onChange={handleChange}
/>{error && (
        <p className="mt-2 text-xs text-red-600">
          {error}
        </p>
      )}
    </div>
  )
}