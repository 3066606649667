import React, { useState, useEffect, useRef } from "react";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "../ui/carousel";
import Autoplay from "embla-carousel-autoplay";

export default function FlowInCards({
  items,
}: {
  items: { title: string; content: React.ReactNode; img: string }[];
}) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);
  const autoplayRef = useRef(
    Autoplay({ delay: 4000, stopOnMouseEnter: true, stopOnInteraction: false })
  );

  useEffect(() => {
    if (!api) return;

    api.on("select", () => {
      const newIndex = api.selectedScrollSnap();
      setCurrent(newIndex);
    });

    return () => {
      api.destroy();
    };
  }, [api]);

  const getStyleForItem = (index: any) => {
    const totalItems = items.length;
    const previousIndex = api ? api.previousScrollSnap() : -1;

    if (index === current) {
      return "opacity-100 "; // Current item is invisible
    } else if (index === previousIndex) {
      return "opacity-0  scale-75"; // Previous item is also invisible
    } else {
      const nextIndex = (current + 1) % totalItems;
      const nextNextIndex = (current + 2) % totalItems;

      switch (index) {
        case nextIndex:
          return " scale-95 opacity-70"; // Next item
        case nextNextIndex:
          return " scale-90 opacity-65"; // Item after next
        default:
          return ""; // Default case for all other items
      }
    }
  };

  return (
    <Carousel
      plugins={[autoplayRef.current]}
      orientation="vertical"
      className="flex flex-col"
      setApi={setApi}
      opts={{
        align: "start",
        duration: 50,
        containScroll: "keepSnaps",
        loop: true,
        watchDrag: false,
      }}>
      <div
        style={{
          WebkitMaskImage:
            "linear-gradient(to bottom, black 40%, transparent 80%)",
          maskImage: "linear-gradient(to bottom, black 40%, transparent 80%)",
        }}>
        <CarouselContent className="max-h-64 last:mb-20">
          {items.map((item, index) => (
            <CarouselItem
              key={item.title + index}
              className={` pt-4 basis-1/3  `}>
              <div
                style={{
                  transition: "opacity 0.5s ease-in, transform 0.5s ease-in",
                }}
                className={` ${getStyleForItem(
                  index
                )}  ori gin-right flex w-full h-full overflow-clip bg-gray-200 dark:bg-gray-900 rounded-xl text-center`}>
                <div className="aspect-square w-28 shrink-0">
                  <img
                    alt={item.title}
                    src={item.img}
                    className="aspect-square"
                  />
                </div>
                <div className="p-4 items-start text-left justify-center flex flex-col">
                  <h2 className="text-base md:text-lg font-bold">{item.title}</h2>
                  <span className="text-sm md:text-base">{item.content}</span>
                </div>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </div>
    </Carousel>
  );
}
