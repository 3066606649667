import React from 'react';

import { ReactComponent as MainIcon } from "assets/page-header-items/main-item-green.svg";
import { CardItemType } from "lib/types";
import CardItemWithIcon from "components/molecules/CardItemWithIcon";
import MonitoredCard from "./MonitoredCard"; // Assuming it's in the same directory
import AnimatedAccordionItem from './AnimatedAccordionItem';
import SustainabilityImage from "assets/img/sustainability/sustainability-1.webp";

import Contacts from "components/sections/Contacts";

import { ImageFrame } from "components/ui/ImageFrame";

const monitorCards: CardItemType[] = [
  {
    title: "Mantenimiento preventivo",
    content:
      "Hortatech realiza un programa de mantenimiento preventivo en los instrumentos de monitoreo para garantizar su correcto funcionamiento y precisión. Las calibraciones, limpiezas y controles periódicos garantizan la coherencia y fiabilidad de los sensores y analizadores.",
  },
  {
    title: "Sensores y analizadores",
    content:
      "Hortatech utiliza sensores y analizadores automatizados para monitorear la calidad del agua, midiendo parámetros como temperatura, pH, conductividad eléctrica, turbidez y concentraciones de químicos y contaminantes. Estos sensores proporcionan datos precisos y fiables en tiempo real.",
  },
  {
    title: "Sistemas de telemetría",
    content:
      "Los datos recopilados por los sensores se transmiten a través de sistemas de telemetría, lo que permite su visualización y análisis en tiempo real. Estos sistemas proporcionan información detallada de la calidad del agua tanto en puntos concretos como en zonas más amplias de la Huerta de Valencia, facilitando la detección temprana de problemas y la toma de decisiones informadas.",
  },
  {
    title: "Colaboración con autoridades y laboratorios",
    content:
      "Hortatech trabaja estrechamente con las autoridades locales y laboratorios especializados para validar y verificar los datos de calidad del agua, asegurando fiabilidad y objetividad en los resultados y proporcionando una base sólida para la gestión y conservación del agua en la Huerta de Valencia.",
  },
  {
    title: "Análisis de datos",
    content:
      "Los datos registrados se analizan con herramientas y software especializados, identificando tendencias y posibles riesgos para la calidad del agua. Algoritmos y modelos avanzados ofrecen predicciones y alertas oportunas en caso de situaciones críticas o cambios significativos en la calidad del agua.",
  },
];

const socialCards: CardItemType[] = [
  {
    title: "Tecnologías avanzadas de riego",
    content:
      "HortaTech utiliza tecnologías de riego de última generación, como sistemas de riego por goteo y riego inteligente basado en la demanda de las plantas. Estas tecnologías permiten una aplicación precisa del agua, reduciendo el desperdicio y optimizando la absorción por parte de las plantas.",
    icon: "water-technologies-smart",
  },
  {
    title: "Monitoreo en tiempo real",
    content:
      "Se implementan sistemas de monitoreo en tiempo real que brindan información precisa sobre el estado y las necesidades hídricas de los cultivos. Esto permite ajustar la cantidad de agua suministrada de manera eficiente, evitando el exceso de riego y optimizando el consumo.",
    icon: "real-time-sensor",
  },
  {
    title: "Reutilización y reciclaje del agua",
    content:
      "HortaTech promueve la reutilización y el reciclaje del agua en diferentes etapas del proceso agrícola. Se implementan sistemas de captación y almacenamiento de agua de lluvia, así como sistemas de tratamiento y reciclaje de aguas residuales, reduciendo la dependencia de fuentes externas y optimizando el uso de recursos hídricos locales.",
    icon: "water-recycle",
  },
  // {
  //   title: "Educación y capacitación",
  //   content:
  //     "Se llevan a cabo programas educativos y capacitaciones dirigidas a agricultores y comunidades locales, con el objetivo de promover prácticas de gestión del agua eficientes y sostenibles. Se proporciona información sobre técnicas de riego adecuadas, la importancia de la conservación del agua y la adopción de tecnologías y prácticas más eficientes.",
  //   icon: "public-education-water",
  // },
  {
    title: "Mejora de la infraestructura hídrica",
    content:
      "HortaTech trabaja en la mejora y optimización de la infraestructura hídrica existente en la Huerta de Valencia. Esto incluye la reparación y modernización de canales y sistemas de distribución de agua, reduciendo las pérdidas por filtración y asegurando un flujo más eficiente del recurso.",
    icon: "infrastructure",
  },
  {
    title: "Sensibilización y participación comunitaria",
    content:
      "HortaTech trabaja en la mejora y optimización de la infraestructura hídrica existente en la Huerta de Valencia. Esto incluye la reparación y modernización de canales y sistemas de distribución de agua, reduciendo las pérdidas por filtración y asegurando un flujo más eficiente del recurso.",
    icon: "work-together",
  },
];

const accordionCards = [
    {
      title: "Conservación de la biodiversidad",
      content:
        "Nos esforzamos por salvaguardar la diversidad biológica de los espacios naturales y parques asociados a la huerta. Esto implica la protección de especies vegetales y animales autóctonas, la restauración de hábitats naturales y la promoción de prácticas agrícolas sostenibles que minimicen el impacto sobre la biodiversidad.",
      icon: "protect-biodiversity",
    },
    {
      title: "Restauración de ecosistemas",
      content:
        "Implementamos programas de restauración y rehabilitación de ecosistemas en colaboración con expertos en conservación. Estos esfuerzos incluyen la revegetación de áreas degradadas, la recuperación de cauces de ríos y arroyos, y la creación de corredores ecológicos que conecten diferentes espacios naturales.",
      icon: "restoration-ecosystem",
    },
    {
      title: "Gestión sostenible del agua",
      content:
        "La gestión eficiente y sostenible del agua es esencial para la preservación de los espacios naturales. Implementamos sistemas de riego inteligentes que optimizan el uso del agua y evitan el desperdicio. Además, trabajamos en la restauración y mantenimiento de humedales y zonas de filtración que contribuyen a la purificación del agua y a la conservación de los ecosistemas acuáticos.",
      icon: "digital-water",
    },
    // {
    //   title: "Educación y sensibilización",
    //   content:
    //     "Promovemos la educación ambiental y la sensibilización sobre la importancia de la preservación de los espacios naturales y parques asociados a la huerta. Realizamos programas de divulgación y actividades educativas dirigidas a la comunidad local y a visitantes, para fomentar la valoración y el respeto por estos entornos naturales.",
    //   icon: "public-education",
    // },

    {
      title: "Colaboración con entidades y organizaciones",
      content:
        "Trabajamos en estrecha colaboración con entidades y organizaciones locales, regionales y nacionales dedicadas a la conservación de la naturaleza. A través de alianzas estratégicas, promovemos la coordinación de esfuerzos y el intercambio de conocimientos y mejores prácticas en materia de preservación de espacios naturales.",
      icon: "collaboration",
    },
  ]

const Sustainability: React.FC = () => {

  return (
    <div className="flex flex-col w-full">
      <section className="h-screen w-screen  overflow-x-clip flex  flex-col md:flex-row relative  py-20">
        <div className="w-full z-10 justify-start md:justify-center  flex h-full gap-y-10 flex-col p-4 md:pr-96 xl:pr-64 2xl:pr-32">
          <div className="flex-col-reverse justify-start items-start gap-y-2 flex">
            <h1 className="max-w-[22ch]">
              Queremos mejorar el <br />
              <span className="inline-flex relative z-0">
                Medio Ambiente
                <div className="absolute -z-10 w-full h-3 bottom-2 bg-green-300 dark:bg-green-700" />
              </span>
            </h1>
            <h2 className=" text-green-600 text-xl font-bold lg:text-2xl uppercase leading-7">
            Impacto Ambiental
            </h2>
          </div>
          <ul className="text-lg *:bg-white/50 *:dark:bg-black/50 *:backdrop-blur-md *:p-2 *:rounded-md grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-4 md:gap-6">
            <li className="flex flex-col">
              <span className="font-semibold text-lg">
                Tecnologías de Riego Avanzadas
              </span>
              <span className="text-base">
                Implementación de sistemas de riego eficientes
              </span>
            </li>
            <li className="flex flex-col">
              <span className="font-semibold text-lg">
                Monitoreo y Reciclaje de Agua
              </span>
              <span className="text-base">
                Uso de tecnologías para evaluar y reutilizar los recursos
                hídricos
              </span>
            </li>
            <li className="flex flex-col">
              <span className="font-semibold text-lg">
                Preservación del Ecosistema
              </span>
              <span className="text-base">
                Acciones para proteger y restaurar los espacios naturales
              </span>
            </li>
          </ul>
        </div>

        <MainIcon className="w-full absolute h-fit bottom-40 z-[1] -right-2 md:bottom-1/2 md:translate-y-1/2 md:right-0  aspect-square ml-auto max-w-40 md:max-w-96 " />
        <span className="w-full h-auto right-0 z-0 flex justify-center bottom-0 absolute">
          {/* <BottomDivider className="w-full max-h-48 md:max-h-full transition-[max-height] min-w-max h-full" /> */}
          <svg
            className="w-full max-h-32 sm:max-h-48 md:max-h-full transition-[max-height] min-w-max h-full"
            width="1440"
            height="181"
            viewBox="0 0 1440 181"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 4L288 37L576 45L864 58L1152 0L1440 24V182H1152H864H576H288H0V4Z"
              className="fill-green-600"
            />
            <path
              d="M0 93L288 17L576 44L864 93L1152 69L1440 70V181H1152H864H576H288H0V93Z"
              className="fill-green-400 dark:fill-green-700"
            />
            <path
              d="M0 92L288 102L576 126L864 79L1152 93L1440 128V181H1152H864H576H288H0V92Z"
              className="fill-green-200 dark:fill-green-800"
            />
          </svg>
        </span>
      </section>
      <section className=" bg-green-200 dark:bg-green-800">
        <div>
          <h2>Estrategias de conservación y ahorro de agua</h2>
          <p>
            Las estrategias de conservación y ahorro de agua implementadas en el
            proyecto Hortatech se centran en maximizar la eficiencia en el uso
            del agua y garantizar su uso sostenible a largo plazo. A
            continuación, se presentan algunas de las principales estrategias
            utilizadas
          </p>
          <div className="grid sm:grid-cols-2 gap-4">
            {socialCards.map((card) => (
              <CardItemWithIcon
                primaryColor="green-500"
                secondaryColor="green-900"
                darkSecondaryColor='green-300'
                key={card.title}
                {...card}
              />
            ))}
          </div>
          <p>
            Estas estrategias de conservación y ahorro de agua implementadas en
            Hortatech tienen como resultado una gestión más eficiente y
            sostenible de los recursos hídricos en la Huerta de Valencia. Al
            optimizar el uso del agua, se logra un equilibrio entre las
            necesidades agrícolas y la preservación de los ecosistemas acuáticos
            y los recursos naturales, garantizando así la disponibilidad del
            agua para las generaciones futuras.
          </p>
        </div>
      </section>
      <section>
        <div>
          <div className="grid  grid-flow auto-rows-[20px,_minmax(150px,_auto)] md:grid-cols-2 colu mns-[2_auto] relative overflow-visible gap-8  w-full">
            {/* <ImageFrame className="max-h-72 w-full object-cover" src={SustainabilityImage2} alt="Sustainability" size="sm" /> */}
            <div className="h-a uto flex flex-col gap-y-3 row-span-2">
              <h2>Monitoreo y mantenimiento de la calidad del agua</h2>
              <p>
              La monitorización y mantenimiento de la calidad del agua en el proyecto Hortatech se realiza a través de tecnologías avanzadas que garantizan evaluaciones precisas y objetivas en la Huerta de Valencia. Estas tecnologías garantizan un seguimiento continuo y en tiempo real de parámetros clave, identificando cualquier desviación o anomalía que pueda afectar al uso del agua. Estas son las principales tecnologías utilizadas:
              </p>
            </div>
            <div className="h-32"></div>

            {monitorCards.map((card, index) => (
              <MonitoredCard key={index} card={card} />
            ))}

            <p>
            Gracias a estas avanzadas tecnologías, Hortatech consigue que los recursos hídricos de la Huerta de Valencia se mantengan en óptimas condiciones para un uso sostenible y la conservación del ecosistema acuático. La información obtenida en tiempo real permite tomar medidas preventivas y correctivas, contribuyendo a la protección y conservación de los recursos hídricos de la región.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div>
          <h2>
            Preservación de espacios naturales y parques asociados a la huerta
          </h2>
          <p>
            La preservación de espacios naturales y parques asociados a la
            huerta es un objetivo fundamental del proyecto Hortatech. A través
            de estrategias y acciones concretas, buscamos proteger y conservar
            estos espacios naturales, que desempeñan un papel crucial en el
            equilibrio ecológico de la Huerta de Valencia. A continuación, se
            presentan los principales enfoques y objetivos de nuestra labor de
            preservación.
          </p>
          <AnimatedAccordionItem items={accordionCards} />
          
        </div>
      </section>

      <section className="bg-green-100 dark:bg-green-800">
        <div>
          <h2>Caudal ecológico y la sostenibilidad en los sistemas de riego</h2>
          <ImageFrame
            className="w-3/4 md:w-1/2 ml-auto mb-4 translate-y-8 z-10 max-h-60 scale-105 object-cover"
            src={SustainabilityImage}
            alt="Sustainability"
            size="sm"
          />
          {/* <img
            src={SustainabilityImage}
            className="rounded-xl w-1/2 ml-auto mb-4 translate-y-8 shadow-md z-10 max-h-60 scale-105 object-cover"
          /> */}
          <div className="bg-white dark:bg-black rounded-xl space-y-2 shadow-sm -translate-y-8 p-4 md:pt-12 md:p-8">
            <p>
              El flujo ecológico y la sostenibilidad en los sistemas de riego
              son conceptos fundamentales para garantizar un uso eficiente y
              responsable del agua en la agricultura y el paisajismo. Estos
              enfoques buscan minimizar el desperdicio de agua, conservar los
              recursos naturales y preservar el equilibrio ecológico en los
              ecosistemas.
            </p>
            <p>
              El flujo ecológico se refiere a la cantidad y calidad del agua
              necesaria para mantener los procesos vitales de los ecosistemas
              acuáticos. En el contexto de los sistemas de riego, implica la
              asignación adecuada de agua para mantener la salud de los cuerpos
              de agua y los hábitats asociados. Esto implica no solo satisfacer
              las necesidades de riego de los cultivos, sino también considerar
              las demandas hídricas de los ecosistemas naturales y las especies
              que dependen de ellos.
            </p>
            <p>
              La sostenibilidad en los sistemas de riego se enfoca en lograr un
              equilibrio entre las necesidades de producción agrícola y la
              conservación de los recursos naturales. Esto implica adoptar
              prácticas de riego eficientes que minimicen las pérdidas de agua,
              como el uso de tecnologías de riego por goteo, aspersión o
              microaspersión. Además, se promueven técnicas de gestión del agua,
              como el monitoreo regular de la humedad del suelo, la programación
              adecuada de los tiempos y frecuencias de riego, y la reutilización
              del agua de drenaje.
            </p>
            <p>
              Para garantizar la sostenibilidad en los sistemas de riego,
              también es importante considerar el uso de fuentes de agua
              alternativas, como aguas residuales tratadas o captación de agua
              de lluvia. Estas prácticas ayudan a reducir la dependencia de los
              recursos hídricos convencionales y a conservar el agua dulce para
              otros usos esenciales.
            </p>
          </div>
        </div>
      </section>
      <section>
        <Contacts />
      </section>
    </div>
  );
};

export default Sustainability;
