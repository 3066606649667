import Icon from "components/Icon";
import React, { useState } from "react";

const CircularShowcase = () => {
  const [activeIndex, setActiveStubIndex] = useState(null);
  const [hoverIndex, setHoverIndex] = useState(null);

  const items = [
    {
      icon: "public-education",
      title: "Divulgación de la historia y el significado",
      content:
        "A través de publicaciones, exposiciones, y material educativo, se difunde información sobre la historia y el papel crucial que desempeñan las infraestructuras hidráulicas en la Huerta de Valencia. Se resaltan los avances tecnológicos, las técnicas tradicionales y la importancia histórica de estos sistemas de riego y canales.",
      className: "rounded-tl-[100%] pt-8 pl-8 origin-bottom-right",
      boxClassName: "origin-bottom-right",
    },
    {
      icon: "public-education",
      title: "Valorización del patrimonio",
      content:
        "Se promueve el respeto y la conservación de las infraestructuras hidráulicas como un patrimonio colectivo. Se destaca su valor arquitectónico, ingenieril y cultural, así como su contribución al desarrollo económico y social de la región. Se busca generar un sentido de pertenencia y orgullo entre los habitantes de la Huerta de Valencia.",
      className: "rounded-tr-[100%] pt-8 pr-8 origin-bottom-left",
      boxClassName: "origin-bottom-left",
    },
    {
      icon: "work-together",
      title: "Visitas guiadas y actividades educativas",
      content:
        "Se organizan visitas guiadas a las infraestructuras hidráulicas, permitiendo a los visitantes conocer de cerca su funcionamiento y su importancia histórica. Además, se llevan a cabo actividades educativas, como talleres y charlas, para promover una comprensión más profunda del patrimonio hidráulico y su relevancia en el pasado y presente de la región.",
      className: "rounded-bl-[100%] pb-8 pl-8 origin-top-right",
      boxClassName: "origin-top-right ",
    },
    {
      icon: "public-education",
      title: "Cooperación con instituciones culturales",
      content:
        "Hortatech establece colaboraciones con instituciones culturales, como museos, centros de interpretación y asociaciones históricas, para desarrollar proyectos conjuntos de investigación, exhibiciones y actividades relacionadas con el patrimonio hidráulico. Estas alianzas fortalecen el alcance y el impacto de las iniciativas de valorización del patrimonio.",
      className: "rounded-br-[100%] pb-8 pr-8 origin-top-left",
      boxClassName: "origin-top-left",
    },
  ];

  const handleMouseEnter = (index: any) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const handleClick = (index: any) => {
    setActiveStubIndex(index);
  };

  const getBackgroundClass = (index: any) => {
    if (index === activeIndex) {
      return "bg-red-300 dark:bg-red-700 z-10 md:scale-110"; // Active item background
    } else if (index === hoverIndex) {
      return "bg-red-200 dark:bg-red-800 md:scale-105"; // Hover item background
    } else {
      return "bg-red-100 dark:bg-red-900"; // Default background
    }
  };

  return (
    <div
      className="grid relative pt-48 md:pt-0 gap-x-14 gap-y-14 md:gap-16 h- w-full
             grid-cols-2
             grid-rows-2
             ">
      <div className="absolute z-20 grid grid-cols-2 gap-1 grid-rows-2 left-1/2 md:top-1/2 -translate-x-1/2 md:-translate-y-1/2">
        {items.map((item, index) => (
          <div
            key={index}
            onMouseOver={() => handleMouseEnter(index)}
            onMouseOut={handleMouseLeave}
            onClick={() => handleClick(index)}
            className={`${getBackgroundClass(index)} ${
              item.className
            }  transition-all duration-200 flex items-end justify-end p-4 h-full w-full outline outline-white dark:outline-black outline-4`}>
            <Icon
              name={item.icon}
              primaryColor="red-800"
              secondaryColor="red-500"
              className="size-9"
            />
          </div>
        ))}
      </div>

      {items.map((item, index) => (
        <React.Fragment key={index}>
          <div
            className={`${getBackgroundClass(index)} ${
              item.boxClassName
            } col-span-full duration-500 ease-in-out md:col-span-1 z-0 row-span-full md:row-span-1 transition-all rounded-xl p-6 md:p-10 space-y-1 h-full w-full`}>
            <h4>{item.title}</h4>
            <p>{item.content}</p>
          </div>
        </React.Fragment>
      ))}

    </div>
  );
};

export default CircularShowcase;
