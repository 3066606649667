import CardItemWithIcon from "components/molecules/CardItemWithIcon";

export default function SixPointImageShowcase({ items, media }: { items: { title: string, content: string, icon: string }[], media: string }) {

	const iconClass = "absolute top-0 left-0 -translate-x-[40%] -translate-y-[40%] size-12 after:content-[''] block after:absolute after:left-0 after:w-full after:h-full after:top-0  after:size-10 after:rounded-full after:bg-green-200 after:dark:bg-gray-950 after:-z-10 p-2.5  overflow-visible"
	return (
		<div className="flex flex-col h-auto">
			<div className="grid md:grid-flow-col grid-rows-[auto,repeat(2,_minmax(0,1fr))] h-fit md:grid-rows-2 grid-cols-2 md:grid-cols-3 gap-10 ">
				<div  className="col-start-1 col-span-2 md:col-span-1 md:col-start-2 flex row-start-1 h-full  md:row-span-2 ">
					<video autoPlay loop muted preload="metadata" className="h-48 md:h-full w-full md:max-h-full object-cover rounded-xl">
						<source src={`${media}#t=0.1`} type="video/mp4" />
					</video>
				</div>
				{items.map((item, index) => {
					return (
						<CardItemWithIcon {...item} boxClass="bg-green-200 col-span-2 md:col-span-1 rounded-xl w-[calc(100%_-_1rem)] ml-4 sm:ml-0 sm:w-full p-6 relative shadow-none" iconClass={iconClass} primaryColor="green-700" secondaryColor="green-950" />
					)
				}
				)}
			</div>
		</div>
	)
}