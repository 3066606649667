import Contacts from "components/sections/Contacts";
import { CardItemType } from "lib/types";
import CardItemWithIcon from "components/molecules/CardItemWithIcon";
import { ReactComponent as MainIcon } from "assets/page-header-items/main-item-blue.svg";
import Icon from "components/Icon";
import ShowcaseImage from "assets/img/front/showcase_1.jpeg";
import { ImageFrame } from "components/ui/ImageFrame";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "components/ui/accordion";
import { NavLink } from "react-router-dom";
import { Button } from "components/ui/button";

import DashboardSwitcher from "./DashboardSwitcher";


const WaterManagement = () => {

  // const initiativeCards = [
  //   {
  //     title: "Rutas turísticas",
  //     content:
  //       "Se están desarrollando rutas turísticas temáticas que permiten a los visitantes explorar y descubrir los elementos del patrimonio hidráulico de la Huerta de Valencia. Estas rutas ofrecen la oportunidad de conocer los sistemas de riego tradicionales, los acueductos históricos, los molinos de agua y otros elementos destacados del patrimonio.",
  //   },
  //   {
  //     title: "Interpretación del patrimonio",
  //     content:
  //       "Se están creando centros de interpretación y espacios de divulgación que brindan información detallada sobre la historia, el valor y la importancia del patrimonio hidráulico de la Huerta de Valencia. Estos espacios permiten a los visitantes sumergirse en el contexto histórico y comprender mejor la relevancia cultural y medioambiental de los sistemas hidráulicos.",
  //   },
  //   {
  //     title: "Actividades educativas y culturales",
  //     content:
  //       "Se llevan a cabo actividades educativas y culturales dirigidas a diferentes públicos, como talleres, charlas, exposiciones y eventos temáticos. Estas actividades tienen como objetivo difundir el conocimiento y fomentar la participación activa de la comunidad en la preservación y valorización del patrimonio histórico.",
  //   },
  //   {
  //     title: "Digitalización y accesibilidad",
  //     content:
  //       "Se está trabajando en la digitalización y accesibilidad del patrimonio histórico, utilizando tecnologías como aplicaciones móviles, realidad aumentada y plataformas digitales. Esto permite que un mayor número de personas puedan acceder a la información y disfrutar de experiencias interactivas que resalten el valor del patrimonio hidráulico.",
  //   },
  // ];

  const waterManagementCards: CardItemType[] = [
    {
      title: "Sostenibilidad Agrícola",
      content:
        "La Huerta de Valencia es reconocida por sus cultivos de frutas, hortalizas y arroz, que dependen en gran medida del suministro adecuado de agua. Una gestión eficiente del agua garantiza la disponibilidad constante de este recurso vital para mantener la productividad agrícola a largo plazo.",
      icon: "preserve-nature",
    },
    {
      title: "Preservación del Ecosistema",
      content:
        "La Huerta de Valencia alberga una gran diversidad de especies vegetales y animales, incluidas aves migratorias y especies autóctonas. La gestión adecuada del agua contribuye a mantener los ecosistemas naturales, los humedales y los espacios verdes asociados con la huerta, asegurando un equilibrio ecológico y protegiendo la biodiversidad.",
      icon: "restoration-ecosystem",
    },
    // {
    //   title: "Protección del Patrimonio Cultural",
    //   content:
    //     "La Huerta de Valencia posee un valioso patrimonio cultural, en particular, las tradicionales infraestructuras hidráulicas que han sido utilizadas durante siglos para el riego de los cultivos. Una gestión adecuada del agua es fundamental para preservar estas estructuras históricas y mantener viva la historia y cultura de la región.",
    //   icon: "maintain-patrimony",
    // },
    {
      title: "Adaptación al Cambio Climático",
      content:
        "El cambio climático está generando desafíos adicionales en términos de disponibilidad de agua en la Huerta de Valencia. Las sequías prolongadas y los patrones climáticos cambiantes afectan la cantidad y calidad del agua disponible. Una gestión eficiente del agua ayuda a enfrentar estos desafíos y garantiza la continuidad de la actividad agrícola y la conservación del patrimonio frente a los impactos del cambio climático.",
      icon: "global-warming",
    },
    {
      title: "Uso Responsable de los Recursos",
      content:
        "La gestión eficiente del agua implica utilizar este recurso de manera responsable y sostenible. Esto incluye la implementación de técnicas de riego eficientes, la promoción de prácticas agrícolas sostenibles y la concienciación sobre el uso responsable del agua entre los agricultores y la comunidad en general.",
      icon: "love-world",
    },
  ];

  const waterManagementItems: CardItemType[] = [
    {
      title: "Tecnología de Riego Inteligente",
      content: "Sensores de fibra óptica y sistemas de riego automatizados para un uso preciso del agua",
      // content:
      //   "La aplicación de tecnologías avanzadas en sistemas de riego permite un uso preciso y eficiente del agua. Sensores de fibra óptica con medición cuantitativa y cualitativa y sistemas de riego automatizados ayudan a determinar las necesidades hídricas de los cultivos y ajustar el riego en consecuencia, evitando el desperdicio de agua.",
      icon: "water-technologies",
    },
    {
      title: "Sistema Predictivo de Riego",
      content: "Usamos el método de la FAO para calcular las necesidades de riego.",
      // content:
      //   "El uso del Cálculo de Necesidades de Riego pone al alcance la metodología más extendida, sencilla y accesible para el cálculo de necesidades de riego. Se trata del método propuesto por la Organización de las Naciones Unidas para la Alimentación y la Agricultura (FAO) que tiene su base en la publicación Evapotranspiración del cultivo - Guías para la determinación de los requerimientos de agua de los cultivos.",
      icon: "predictive-system",
    },
    {
      title: "Gestión Basada en Datos y Análisis",
      content: "Plataformas digitales para monitorear y analizar el uso del agua en tiempo real",
      // content:
      //   "La recopilación y el análisis de datos relacionados con la gestión del agua permiten tomar decisiones informadas y optimizar los recursos disponibles. Mediante el uso de plataformas digitales y sistemas de información, se puede monitorear y analizar en tiempo real el uso del agua, identificar áreas de mejora y tomar medidas correctivas de manera oportuna.",
      icon: "data-management-wide",
    },
  ];

  return (
    <div className="flex flex-col w-full">
      <section className="h-screen  md:w-screen overflow-x-clip flex  flex-col md:flex-row relative  py-20">
        <div className="w-full justify-start z-10 md:justify-center  flex h-full gap-y-5 md:gap-y-10 flex-col p-4 md:pr-96 xl:pr-64 2xl:pr-32">
          <div className="flex-col-reverse w-full justify-start items-start gap-y-2 flex">
            <h1 className="max-w-[22ch]">
              Optimización y Sostenibilidad del Agua en la  {""}
              <span className="inline-flex relative z-0">
                Huerta de Valencia
                <div className="absolute -z-10 w-full h-3 bottom-2 bg-blue-300" />
              </span>
            </h1>
            <h2 className=" text-blue-600 text-xl font-bold lg:text-2xl uppercase leading-7">
              Gestion del Agua
            </h2>
          </div>
          <ul className="text-lg *:bg-white/50 *:dark:bg-black/50 *:backdrop-blur-md *:p-2 *:rounded-md grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-4 md:gap-6">
            <li className="flex flex-col">
              
              <span className="text-base">
                Implementación de tecnologías avanzadas para un uso eficiente
                del agua
              </span>
            </li>
            <li className="flex flex-col">
              
              <span className="text-base">
                Protección y preservación de los ecosistemas y el patrimonio
                cultural
              </span>
            </li>
            <li className="flex flex-col">
              
              <span className="text-base">
                Adaptación a los desafíos del cambio climático con soluciones
                sostenibles
              </span>
            </li>
          </ul>
        </div>

        <MainIcon className="w-full absolute h-fit bottom-40 -right-2 md:bottom-1/2 md:translate-y-1/2 md:right-0  aspect-square ml-auto max-w-40 md:max-w-96 " />
        <span className="w-full h-auto right-0  flex justify-center bottom-0 absolute">
          <svg
            className="w-full max-h-32 sm:max-h-48 md:max-h-full transition-[max-height] min-w-max h-full"
            width="1440"
            height="198"
            viewBox="0 0 1440 198"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 49.8667L26.72 58.96C53.28 67.9067 106.72 86.0933 160 73.3333C213.28 60.5733 266.72 17.16 320 15.1066C373.28 13.2 426.72 52.8 480 64.8267C533.28 76.7067 586.72 61.16 640 55.2934C693.28 49.4267 746.72 53.24 800 54.7066C853.28 56.1733 906.72 55.2933 960 50.3066C1013.28 45.4666 1066.72 36.6666 1120 39.3066C1173.28 42.0933 1226.72 56.1734 1280 51.6267C1333.28 46.9334 1386.72 23.4667 1413.28 11.7333L1440 0V198H1413.28C1386.72 198 1333.28 198 1280 198C1226.72 198 1173.28 198 1120 198C1066.72 198 1013.28 198 960 198C906.72 198 853.28 198 800 198C746.72 198 693.28 198 640 198C586.72 198 533.28 198 480 198C426.72 198 373.28 198 320 198C266.72 198 213.28 198 160 198C106.72 198 53.28 198 26.72 198H0V49.8667Z"
              className="fill-blue-500 "
            />
            <path
              className="fill-blue-300 dark:fill-blue-700"
              d="M0 55.7334L26.72 57.2C53.28 58.6667 106.72 61.6 160 58.3733C213.28 55.2933 266.72 45.9067 320 54.56C373.28 63.0667 426.72 89.4667 480 97.24C533.28 105.16 586.72 94.3067 640 74.5067C693.28 54.7067 746.72 25.96 800 17.3067C853.28 8.80001 906.72 20.5334 960 41.0667C1013.28 61.6 1066.72 90.9333 1120 103.84C1173.28 116.893 1226.72 113.373 1280 112.64C1333.28 111.907 1386.72 113.96 1413.28 114.84L1440 115.867V198H1413.28C1386.72 198 1333.28 198 1280 198C1226.72 198 1173.28 198 1120 198C1066.72 198 1013.28 198 960 198C906.72 198 853.28 198 800 198C746.72 198 693.28 198 640 198C586.72 198 533.28 198 480 198C426.72 198 373.28 198 320 198C266.72 198 213.28 198 160 198C106.72 198 53.28 198 26.72 198H0V55.7334Z"
            />
            <path
              d="M0 115.867L26.72 113.667C53.28 111.467 106.72 107.067 160 105.16C213.28 103.107 266.72 103.693 320 111.027C373.28 118.36 426.72 132.44 480 135.667C533.28 138.893 586.72 130.973 640 123.933C693.28 116.893 746.72 110.44 800 114.106C853.28 117.773 906.72 131.56 960 138.893C1013.28 146.227 1066.72 147.107 1120 135.227C1173.28 123.2 1226.72 98.2665 1280 95.6265C1333.28 92.8399 1386.72 112.493 1413.28 122.173L1440 132V198H1413.28C1386.72 198 1333.28 198 1280 198C1226.72 198 1173.28 198 1120 198C1066.72 198 1013.28 198 960 198C906.72 198 853.28 198 800 198C746.72 198 693.28 198 640 198C586.72 198 533.28 198 480 198C426.72 198 373.28 198 320 198C266.72 198 213.28 198 160 198C106.72 198 53.28 198 26.72 198H0V115.867Z"
              className="fill-blue-100 dark:fill-blue-900"
            />
          </svg>
        </span>
      </section>
      <section className=" bg-blue-100 dark:bg-blue-900">
        <div>
          <h2 className="">Importancia de la Gestión del Agua en la Huerta de Valencia</h2>
          <div className="grid md:grid-cols-2 md:grid-rows-5 gap-4">
            <p>
              La gestión del agua desempeña un papel crucial en la Huerta de
              Valencia, una región conocida por su rica tradición agrícola y su
              valioso patrimonio cultural. A continuación, se detallan algunas
              razones por las cuales la gestión del agua es de vital importancia
              en esta área:
            </p>
            {waterManagementCards.map((card) => (
              <CardItemWithIcon
                boxClass="row-span-2"
                primaryColor="blue-500"
                secondaryColor="blue-900"
                darkSecondaryColor="blue-100"
                key={card.title}
                {...card}
              />
            ))}
            <p>
              La gestión del agua en la Huerta de Valencia es esencial para
              garantizar la sostenibilidad agrícola, proteger el valioso
              patrimonio cultural y natural, adaptarse al cambio climático y
              promover el uso responsable de los recursos hídricos. Es
              fundamental implementar soluciones innovadoras y estrategias
              eficientes para una gestión efectiva del agua y asegurar un futuro
              sostenible para esta región única.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className=" relative md:grid flex lg:grid-cols-[minmax(0,1fr)_8rem] md:grid-rows-1 lg:items-center flex-col lg:flex-row gap-10 md:gap-20">
          <div className="flex flex-col md:gap-y-10 lg:py-16 mr-4 gap-6 mt-10">
            <h2>Soluciones Innovadoras para una Gestión Eficiente del Agua</h2>
            <div className="md:grid md:grid-cols-3 md:grid-rows-1 flex-1 mx-2 lg:mx-0 flex flex-col gap-8 md:gap-8">
              {waterManagementItems.map((i) => (
                <CardItemWithIcon
                  title={i.title}
                  content={i.content}
                  icon={i.icon}
                  primaryColor="blue-500"
                  secondaryColor="blue-900"
                  darkSecondaryColor="blue-100"
                  boxClass="shadow-none bg-transparent dark:bg-transparent p-0"
                />
              ))}
            </div>
            <p>
              Estas soluciones innovadoras, combinadas con la concienciación y
              la participación activa de agricultores y comunidades locales,
              contribuyen a una gestión más eficiente y sostenible del agua en
              la Huerta de Valencia. Al adoptar tecnologías avanzadas y
              prácticas agrícolas responsables, podemos garantizar la
              disponibilidad de agua a largo plazo y preservar los recursos
              naturales y culturales de esta región tan especial.
            </p>
          </div>
          <div className="flex w-full h-full justify-start lg:mb-0 my-4 translate-x-12 scale-125 lg:translate-x-0 md:scale-110 lg:scale-100">
            <div className="w-fit flex h-full lg:aspect-[2/1] ">
              <ImageFrame src={ShowcaseImage} alt="Showcase Image"
              ></ImageFrame>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div>
          <h2 className="text-center">
          Sistemas de Control y Monitoreo en Tiempo Real
          </h2>
          <p className="text-center">
           Los sensores proporcionan información precisa y actualizada sobre los recursos hídricos, permitiendo decisiones más efectivas y una respuesta rápida ante situaciones anómalas.
            {/* En la Huerta de Valencia, vamos a implementar sistemas de control y
            monitoreo en tiempo real para optimizar la gestión del agua y
            garantizar un uso eficiente de este recurso vital. Estos sistemas
            proporcionaran información en tiempo real sobre el estado de los
            recursos hídricos y permiten tomar decisiones basadas en datos
            precisos. A continuación, se describen algunas de las
            características y beneficios de estos sistemas: */}
          </p>

          <DashboardSwitcher />
          
          {/* <div>
            <h4>
              Eficiencia en el mantenimiento 
              </h4>
              Los
            sistemas de monitoreo en tiempo real también ayudan a optimizar el
            mantenimiento de la infraestructura de agua. Al detectar de manera
            temprana posibles fallas o problemas, se pueden tomar medidas
            preventivas y programar el mantenimiento de manera más eficiente,
            reduciendo los costos y minimizando las interrupciones en el
            suministro de agua.
          </div> */}
          <div className="flex flex-col md:flex-row gap-4 p-4 bg-blue-50/50 border-blue-200 dark:bg-blue-800/50 dark:border-blue-800 border-2 rounded-2xl items-center">

            <p>
              La implementación de sistemas de control y monitoreo en tiempo real
              en la Huerta de Valencia mejora significativamente la gestión del
              agua al proporcionar información precisa y actualizada sobre los
              recursos hídricos. Esto permite una toma de decisiones más efectiva,
              un uso eficiente del agua y una respuesta rápida ante situaciones
              anómalas. En última instancia, estos sistemas contribuyen a
              garantizar la sostenibilidad de la Huerta de Valencia y la
              conservación de sus recursos naturales y culturales.
            </p>
            <Button className="w-fit" size="lg" >
              <NavLink to="/water-management">
                Saber más
              </NavLink>
            </Button>
        </div>
          </div>
      </section>
      <section className=" bg-blue-50 dark:bg-blue-950">
        <div>
          <h2 className="text-center">
          Monitoreo y Preservación de la Calidad del Agua
          </h2>
          <p>
            En la Huerta de Valencia, el monitoreo y la preservación de la
            calidad del agua son aspectos fundamentales para garantizar la
            sostenibilidad y la salud de los recursos hídricos. A continuación,
            se presentan algunas estrategias y medidas utilizadas para llevar a
            cabo el monitoreo y la preservación de la calidad del agua en la
            Huerta de Valencia:
          </p>
          
          <Accordion
            defaultValue="item-1"
            className="overflow-clip  gap-y-2 flex flex-col"
            type="single"
            collapsible>
            {[
              // {
              //   title: "Análisis de la calidad del agua",
              //   content:
              //     "Se realizan análisis periódicos de la calidad del agua en diferentes puntos de la Huerta de Valencia, incluyendo fuentes de agua, canales de riego y pozos. Estos análisis evalúan parámetros como el pH, la concentración de nutrientes, la presencia de contaminantes y otros indicadores de la calidad del agua. Esto permite detectar cualquier cambio o problema en la calidad del agua y tomar medidas correctivas de manera oportuna.",
              //   icon: "water-quality",
              // },
              {
                title: "Monitoreo continuo",
                content:
                  "Se implementan sistemas de monitoreo continuo que permiten obtener datos en tiempo real sobre la calidad del agua. Estos sistemas utilizan sensores y tecnologías avanzadas para medir diferentes parámetros, como la turbidez, la conductividad eléctrica y la presencia de contaminantes específicos. El monitoreo continuo ayuda a identificar tendencias, cambios estacionales y eventos anómalos que podrían afectar la calidad del agua.",
                icon: "continuous-monitoring",
              },
              {
                title: "Control de fuentes de contaminación",
                content:
                  "Se implementan medidas para controlar y reducir las fuentes de contaminación que pueden afectar la calidad del agua en la Huerta de Valencia. Esto incluye la implementación de prácticas agrícolas sostenibles, la promoción del uso responsable de productos químicos y fertilizantes, y la regulación de actividades industriales cercanas a los recursos hídricos. Al controlar las fuentes de contaminación, se puede prevenir la degradación de la calidad del agua y proteger los ecosistemas acuáticos.",
                icon: "pollution-control",
              },
              {
                title: "Educación y concienciación",
                content:
                  "Se llevan a cabo campañas de educación y concienciación para informar a los agricultores, residentes y usuarios de la Huerta de Valencia sobre la importancia de preservar la calidad del agua. Se promueven prácticas responsables de uso del agua, como el riego eficiente y el uso adecuado de productos químicos. Además, se fomenta la participación activa de la comunidad en la protección de los recursos hídricos y la denuncia de actividades que puedan afectar negativamente la calidad del agua.",
                icon: "public-education",
              },
              {
                title: "Colaboración con entidades reguladoras",
                content:
                  "Se establecen alianzas y colaboraciones con entidades reguladoras y organismos encargados de la gestión del agua para garantizar el cumplimiento de los estándares de calidad del agua establecidos. Esto incluye la coordinación de esfuerzos en el monitoreo, la recopilación de datos y la implementación de medidas de protección y conservación de la calidad del agua.",
                icon: "public-education",
              },
            ].map((card, i) => (
              <AccordionItem
                className="*:overflow-visible overflow-clip hover:bg-blue-200 dark:hover:bg-blue-700 dark:hover:border-blue-700 hover:border-blue-200 transition-all border dark:border-blue-500 border-white dark:data-[state=open]:border-blue-500 data-[state=open]:border-white data-[state=open]:shadow-sm md:py-2 rounded-lg px-4 md:px-8 data-[state=closed]:mx-4   data-[state=open]:pr-8 md:data-[state=open]:pr-12 data-[state=open]:bg-white dark:data-[state=open]:bg-blue-500"
                value={`item-${i + 1}`}>
                <AccordionTrigger className="text-lg text-left decoration-transparent ">
                  {card.title}
                </AccordionTrigger>
                <AccordionContent className="flex ">
                  {card.content}
                  <div>
                    <Icon
                      primaryColor="blue-100"
                      secondaryColor="blue-200"
                      className=" translate-x-1/2 z-10 scale-150"
                      name={card.icon}
                    />
                  </div>
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
          <p>
            El monitoreo y la preservación de la calidad del agua en la Huerta
            de Valencia son fundamentales para proteger los ecosistemas
            acuáticos, asegurar la salud de los cultivos y promover la
            sostenibilidad a largo plazo. Mediante el uso de tecnologías
            avanzadas, el control de fuentes de contaminación y la colaboración
            con la comunidad y las entidades reguladoras, se trabaja en conjunto
            para preservar la calidad del agua y garantizar su disponibilidad
            para las generaciones futuras.
          </p>
        </div>
      </section>
      
      <section>
      <Contacts />
      </section>
    </div>
  );
};

export default WaterManagement;
