import { Button } from "components/ui/button"
import CardItemWithIcon from "components/molecules/CardItemWithIcon";

export default function Contacts() {
	return (
		<div className="flex flex-col gap-4 lg:flex-row lg:px-16">
			<div className="lg:w-full lg:py-4 flex items-center">
				<CardItemWithIcon boxClass="dark:bg-transparent shadow-none rounded-none py-2 px-5 lg:border-l-0 border-l-2 border-l-blue-600" title="Contacto" content="Para más información sobre HortaTech y nuestras iniciativas, por favor contáctenos en" >
					<Button variant={"ghost"} className="bg-green-500 dark:bg-green-700 w-fit rounded-full">Contacto</Button>
				</CardItemWithIcon>
			</div>
			<div className="lg:bg-blue-50 lg:dark:bg-blue-900 lg:w-full lg:rounded-xl lg:py-4 flex items-center">
				<CardItemWithIcon boxClass="dark:bg-transparent shadow-none rounded-none py-2 px-5 lg:border-l-0 border-l-2 border-l-blue-600 bg-transparent" title="Queres saber más?" content="Prueba el Dashboard ahora con acceso limitado">
					<Button variant={"ghost"} className="bg-green-500 dark:bg-green-700 w-fit rounded-full">Al Dashboard</Button>
				</CardItemWithIcon>
			</div>
			<div className="lg:hidden flex items-center">
				<CardItemWithIcon boxClass="dark:bg-transparent shadow-none rounded-none py-2 px-5 lg:border-l-0 border-l-2 border-l-blue-600" title="Ver el Video" content="">
					<Button variant={"ghost"} className="bg-green-500 dark:bg-green-700 w-fit rounded-full">▶️</Button>
				</CardItemWithIcon>
			</div>
		</div>
	)
}