import { useState, useEffect } from "react";
import "@fontsource-variable/outfit";
import ScrollToTop from "lib/ScrollToTop"; // Make sure the path is correct

import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home, AboutUs, Technology, Contact, SocialImpact, Sustainability, WaterManagement, PrivacyPolicy, Legal, Cookies } from './pages';
import NavBar from "components/NavBar";
import Footer from "components/sections/Footer";
const App = () => {

  const [userSettings] = useState({
    darkMode: 'system',
  });

  useEffect(() => {
    const className = 'dark';
    const element = document.documentElement;

    switch (userSettings.darkMode) {
      case 'dark':
        element.classList.add(className);
        break;
      case 'light':
        element.classList.remove(className);
        break;
      case 'system':
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          element.classList.add(className);
        } else {
          element.classList.remove(className);
        }
        break;
      default:
        break;
    }
  }, [userSettings.darkMode]);

  return (
    <BrowserRouter>
          <ScrollToTop /> 

			<NavBar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/water-management" element={<WaterManagement />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/sustainability" element={<Sustainability />} />
        <Route path="/social-impact" element={<SocialImpact />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/terms-and-conditions" element={<Legal />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
