import { ReactComponent as Logo } from "assets/logo.svg";

import { Link } from "react-router-dom";

const Footer = () => {
  const navItems = [
    { name: "Inicio", href: "/" },
    { name: "Impacto Social", href: "/social-impact" },
    { name: "Sostenibilidad", href: "/sustainability" },
    { name: "Tecnológica", href: "/technology" },
    { name: "Sobre Nosotros", href: "/about-us" },
    { name: "Contacto", href: "/contact" },
  ];

  const legalItems = [
    { name: "Términos y Condiciones", href: "/terms-and-conditions" },
    { name: "Política de Privacidad", href: "/privacy" },
    { name: "Cookies", href: "/cookies"}
  ];
  return (
    <footer className="bg-gray-950">
      <div className="container max-w-4xl py-10 px-4 mx-auto">
        <div className="flex flex-col md:flex-row">
          <div className="text-left flex flex-col gap-y-2 items-start ">
            <Logo className="w-auto h-8 mb-3" />
            <div>
              <Link
                to="/dashboard"
                className={` 
                  font-light text-white hover:text-gray-100  whitespace-nowrap cursor-pointer relative z-[1] text-sm`}>
                Dashboard
              </Link>
            </div>
            <div className="flex gap-4 flex-wrap">
              {navItems.map((item, index) => (
                <Link
                  to={item.href}
                  className={` 
                  font-light text-white hover:text-gray-100  whitespace-nowrap cursor-pointer relative z-[1] text-sm`}>
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="flex gap-4 flex-wrap">
              {legalItems.map((item, index) => (
                <Link
                  to={item.href}
                  className={` 
                  font-light text-white hover:text-gray-100  whitespace-nowrap cursor-pointer relative z-[1] text-sm`}>
                  {item.name}
                </Link>
              ))}
            </div>
            <p className="text-sm mt-3 text-gray-500">
              © 2024 HortaTech. Todos los derechos reservados.
            </p>
          </div>
          <div className="text-center md:text-right mt-10 md:mt-0">
            <p className="text-lg opacity-70">
              {/* © 2023 The Tasty Bits. Todos los derechos reservados. */}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
