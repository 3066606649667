import { useState } from "react";

// import HDScreenshot from "assets/img/hd-screenshot.png";
import ScreenshotAdaptableDashboard from "assets/img/technology/screenshot-adaptable-dashboard.webp";
import ScreenshotAnalysis from "assets/img/technology/screenshot-analysis.webp";
import ScreenshotEvents from "assets/img/technology/screenshot-events.webp";
import ScreenshotMaps from "assets/img/technology/screenshot-maps.webp";
import ScreenshotUserManagement from "assets/img/technology/screenshot-user-management.webp";
import { Button } from "components/ui/button";

const DesktopSwitcher = () => {
  type Category = {
    key: string;
    title: string;
    description: string;
    image: string;
  };

  const desktopItems: Category[] = [
    {
      key: "adaptable",
      title: "Adaptable",
      description:
        "El dashboard ofrece una interfaz adaptable y personalizable que se ajusta a las necesidades y preferencias de cada usuario. Puede personalizar los paneles de control, los gráficos y los informes para reflejar la información más relevante para su operación",
      image: ScreenshotAdaptableDashboard,
    },
    {
      key: "monitor",
      title: "Monitoreo",
      description:
        "Recopila datos en tiempo real de diversas variables relacionadas con el ciclo del agua, como caudal, presión, nivel de agua, calidad del agua y consumo. Estos datos son presentados de forma clara y visual, lo que permite una fácil interpretación y seguimiento de los parámetros clave",
      image: ScreenshotMaps,
    },
    {
      key: "alert",
      title: "Alertas y notificaciones",
      description:
        "Está diseñado para generar alertas y notificaciones en caso de que se detecten anomalías o situaciones de riesgo. Esto permite una respuesta más rápida y eficiente ante cualquier evento inesperado",
      image: ScreenshotEvents,
    },
    // {
    //   key: "integration",
    //   title: "Integración de sistemas",
    //   description:
    //     "Puede integrar y sincronizar diferentes sistemas y dispositivos utilizados en la gestión del ciclo del agua, como sensores, medidores, válvulas y bombas. Esto permite una gestión centralizada y coordinada de todas las etapas del ciclo del agua, optimizando la operación y el mantenimiento de la infraestructura hídrica",
    //   image: HDScreenshot,
    // },
    {
      key: "analysis",
      title: "Análisis",
      description:
        "Puede generar informes y análisis detallados sobre el desempeño y la eficiencia del ciclo del agua. Estos informes proporcionan datos históricos, tendencias y estadísticas que ayudan a evaluar el rendimiento y tomar decisiones estratégicas a largo plazo.",
      image: ScreenshotAnalysis,
    },
    { key: "gestionusuarios",
      title: "Gestión de usuarios",
      description:
        "Permite gestionar y controlar los permisos de acceso de los usuarios, asignando roles y privilegios específicos a cada usuario. Esto garantiza la seguridad y la confidencialidad de los datos, así como el cumplimiento de las normativas y políticas internas de la organización",
      image: ScreenshotUserManagement,
    }
  ];

  const [selectedDesktopKey, setSelectedDesktopKey] = useState<string | null>(
    "monitor"
  );

  const filterDesktop = (category: Category): void => {
    setSelectedDesktopKey(category.key); // Update selected category based on key
  };

  // Find the selected category object based on selectedPhoneKey
  const selectedDesktopItem = desktopItems.find(
    (category) => category.key === selectedDesktopKey
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="mt-3  flex flex-row bg-gray-700 ga md:bg-transparent scroll-px-8 w-screen md:w-auto -ml-8 md:mx-auto snap-x snap-mandatory space-x-3 overflow-x-scroll p-2 px-8 hide-scrollbar">
        {desktopItems.map((category) => (
          <Button
            variant="ghost"
            key={category.key}
            className={`rounded-full snap-start ${
              selectedDesktopKey === category.key
                ? "  bg-gray-500 text-secondary dark:text-secondary"
                : "  shrink-0 transition-all "
            }`}
            onClick={() => filterDesktop(category)}>
            {category.title}
          </Button>
        ))}
      </div>
      {selectedDesktopItem && (
        <div className=" md:p-4 flex flex-col w-full items-center gap-4 ">
          <div className=" w-full">
            <div className="relative mx-auto transition-all border-gray-800 dark:border-gray-800 bg-gray-800 border-[16px] lg:border-[20px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px] lg:max-w-[700px] lg:h-[400px]">
              <div className="transition-all rounded-sm overflow-hidden h-[140px] md:h-[262px] lg:h-[360px] ">
                <img
                  src={selectedDesktopItem.image}
                  className="transition-all dark:hidden h-[140px] md:h-[262px] lg:h-[360px] w-full rounded-sm"
                  alt=""
                />
                <img
                  src={selectedDesktopItem.image}
                  className="transition-all hidden dark:block h-[140px] md:h-[262px] lg:h-[360px] w-full rounded-sm"
                  alt=""
                />
              </div>
            </div>
            <div className="transition-all relative mx-auto bg-gray-700 dark:bg-gray-700 rounded-b-xl h-[24px] max-w-[301px] md:h-[42px] md:max-w-[512px] lg:max-w-[700px] lg:h-[56px] "></div>
            <div className="transition-all relative mx-auto bg-gray-800  rounded-b-xl h-[55px] max-w-[83px] md:h-[95px] md:max-w-[142px] lg:max-w-[160px] lg:h-[110px] "></div>
          </div>

          {/* <img src={selectedDesktopItem.image} alt={selectedDesktopItem.title} className="w-full h-80 rounded-t-lg" /> */}
          <div className="md:p-2">
            <p className="text-gray-200 text-left md:text-center min-h-56 md:min-h-32 dark:text-gray-200">
              {selectedDesktopItem.description}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopSwitcher;



// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import HDScreenshot from "assets/img/hd-screenshot.png";
// import { Button } from 'components/ui/button';

// interface Section {
//   key: string;
//   text: string;
//   title: string;
//   className: string;  // This will apply specific styles to each section
// }
// const DashboardSwitcher: React.FC = () => {
//   const [activeSection, setActiveSection] = useState<Section | null>(null);

//   const sections: Section[] = [
//     { key: 'control-automatizado', text: 'Los sistemas de control en tiempo real permiten ajustar automáticamente los flujos de agua y los ciclos de riego en función de las necesidades reales de los cultivos y las condiciones climáticas. Esto garantiza que se suministre la cantidad de agua adecuada en el momento adecuado, evitando tanto el desperdicio como la escasez de agua.', title: 'Control Automatizado',
//       className: "left-[13.4%] top-[11.2%]  w-[42.4%] h-[42.9%]"
//      },
//      { key: 'alertas-alarmas', text: 'Los sistemas de monitoreo en tiempo real están equipados con alertas y alarmas que se activan cuando se detectan condiciones anormales, como fugas de agua, niveles bajos de agua o problemas de calidad del agua. Estas alertas permiten una respuesta rápida y eficiente, evitando pérdidas significativas y minimizando el impacto en los cultivos y el entorno.', title: 'Alertas y Alarmas',
//        className: "right-[0.8%] top-[11.2%]  w-[42.4%] h-[42.9%]" },
//        { key: 'visualización', text: 'Los datos recopilados por los sistemas de monitoreo en tiempo real se presentan en paneles de control intuitivos y visuales. Esto permite a los gestores de agua y agricultores acceder a información clara y comprensible sobre el estado de los recursos hídricos.', title: 'Visualización y Análisis de Datos',
//          className: "left-[13.4%] bottom-[1.55%]  w-[42.4%] h-[42.9%]"
//         },
//     { key: 'sensores-estaciones', text: 'Se instalan sensores en diferentes puntos estratégicos de la Huerta de Valencia, como fuentes de agua, canales de riego y campos de cultivo. Estos sensores recopilan datos sobre el nivel de agua, la calidad del agua, la temperatura y otros parámetros relevantes.', title: 'Sensores y Estaciones de Monitoreo',
//       className: "right-[0.8%] bottom-[1.55%]  w-[42.4%] h-[42.9%]"
//      },
//   ];

//   const toggleSection = (section: Section) => {
//     if (activeSection !== null && activeSection.key === section.key) {
//       setActiveSection(null);
//       return;
//     } else {
//     setActiveSection(activeSection === section ? null : section);
//     }
//   };

//   return (

//         <div className='flex flex-col gap-4'>
//           <div className='flex w-full justify-between'>

//           {sections.map(section => (
//             <Button key={section.key} className="w-fit" size="lg" onClick={() => toggleSection(section)} title={section.title}>
//               {section.title}
//             </Button>
//           ))}
//         </div>
//         <div className=" w-full">
//             <div className="relative mx-auto transition-all border-gray-800 dark:border-gray-800 bg-gray-800 border-[16px] lg:border-[20px] rounded-t-xl h-[172px] max-w-[301px] md:h-[294px] md:max-w-[512px] lg:max-w-[700px] lg:h-[400px]">
//               <div className="transition-all rounded-sm overflow-hidden h-[140px] md:h-[262px] lg:h-[360px] ">

//           <div className="relative rounded-lg shadow-xl overflow-clip">
//             {activeSection && (
//               <div className={`absolute transition-all bg-transparent rounded-md backdrop-filter z-10 outline outline-1 outline-gray-600 backdrop-brightness-[1.3] ${activeSection.className}`}>
//               </div>
//             )}
//             {activeSection && (
//               <div className="absolute inset-0 backdrop backdrop-brightness-75">
//               </div>
//             )}
//             <img src={HDScreenshot} alt="Huerta de Valencia" className="w-full h-full object-cover" />
//           </div>
//             </div>
//           </div>
//           <div className="transition-all relative mx-auto bg-gray-700 dark:bg-gray-700 rounded-b-xl h-[24px] max-w-[301px] md:h-[42px] md:max-w-[512px] lg:max-w-[700px] lg:h-[56px] "></div>
//           <div className="transition-all relative mx-auto bg-gray-800  rounded-b-xl h-[55px] max-w-[83px] md:h-[95px] md:max-w-[142px] lg:max-w-[160px] lg:h-[110px] "></div>
//         </div>
//           {activeSection && (
//             <div>
// <h4>

//               {activeSection.title}
// </h4>
//             <p>
//               {activeSection.text}
//             </p>
//             </div>
//             )}
//         </div>
//   );
// }

// export default DashboardSwitcher;
