import { ReactNode, useEffect, useState } from "react";
import { type CarouselApi } from "components/ui/carousel";
import React from "react";
import { Button } from "../ui/button";
import Icon from "../Icon";
import CarouselCard from "../CarouselCard";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "components/ui/carousel";
import "./animationStyles.css";
// interface CarouselProps<T> {
//   readonly items: T[];
//   readonly renderItem: (
//     props: CarouselRenderItemProps<T>
//   ) => React.ReactElement<CarouselItemProps>;
//   // readonly title: string;
//   readonly buttonsColor?: string;
//   readonly axis: "x" | "y";
//   readonly sideBlur: boolean;
//   readonly disableScroll?: boolean;
//   readonly autoScroll?: boolean;
// }

// interface CarouselRenderItemProps<T> {
//   readonly item: T;
//   readonly isActive: boolean;
//   readonly offset: number;
// }

// interface CarouselItemProps {
//   readonly children?: React.ReactNode;
//   readonly offset?: number;
// }

export default function CarouselFeatureSwitcher({
  items,
  variant = "simple",
  sideBlur = true,
  options = {},
  itemsPerPage = 3,
}: {
  items: { title: string; content: string; header: ReactNode }[];
  variant?: "simple" | "active-indicator" | "blurred-bg";
  sideBlur?: boolean;
  itemsPerPage?: number;  
  options?: { [key: string]: any };
}) {
  const [api, setApi] = useState<CarouselApi>();
  const [current, setCurrent] = useState(0);

  console.log("api", api)
  console.log("items", items);
  console.log("items-length", items.length);
  console.log("items-length - 2", items.length - 2);
  console.log("current", current);

  console.log("options", options);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCurrent(api.selectedScrollSnap());
    api.on("select", () => {
      setCurrent(api.selectedScrollSnap());
    });
  }, [api]);

  // const [itemsPerPage, setItemsPerPage] = useState(3);

  return (
    <Carousel
      orientation="horizontal"
      className="flex mx-auto flex-col"
      setApi={setApi}
      opts={{
        ...options,
      }}>
      <div className="flex flex-row gap-1 mb-4 ml-auto">
        <Button
          variant={"ghost"}
          className="bg-white dark:bg-gray-900 rounded-full p-0 size-8"
          onClick={() => api?.scrollPrev()}>
          <Icon name="arrow" primaryColor="canvasText"></Icon>
        </Button>
        <Button
          variant={"ghost"}
          className="bg-white dark:bg-gray-900 rounded-full p-0 size-8 rotate-180"
          onClick={() => api?.scrollNext()}>
          <Icon name="arrow" primaryColor="canvasText"></Icon>
        </Button>
      </div>

      <div
      className={`${sideBlur && "sideblur-mask"} ${sideBlur && (current < items.length - 2) ? "hide-mask" : ""}`}
        >
        <CarouselContent className="flex justify-ce nter">
          {items.map((item, i) => (
            <CarouselItem 
            key={i}
            className={`basis-full mx-auto ${itemsPerPage === 1 && "md:basis-2/3"}
            ${itemsPerPage === 2 && "md:basis-1/2"}
            ${itemsPerPage === 3 && "md:basis-1/3"}
            ${itemsPerPage === 4 && "md:basis-1/4"}
            `}>
              <CarouselCard
                variant={variant}
                {...item}
                isActive={i === current}
              />
            </CarouselItem>
          ))}
        </CarouselContent>
      </div>
    </Carousel>
  );
}
