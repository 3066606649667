const PrivacyPolicy = () => {
  return (
    <section>
      <div>
        <h1>Política de Privacidad</h1>
        <div className="flex flex-col gap-y-2">
          <p>
          Si estás leyendo este documento quiere decir que estás interesado en Hortatech. Agradecemos el interés mostrado por nuestro proyecto, por ello, con el mismo interés, queremos informarte de nuestra Política de Privacidad y explicarte lo que hacemos con los datos personales que nos suministras, así como aquellos que recabamos si navegas a través de nuestra web.
          </p>
        </div>

        <h2>1. IDENTIFICACIÓN DEL RESPONSABLE Y DEL ENCARGADO DEL TRATAMIENTO.</h2>
        <div className="flex flex-col gap-y-2">
          <p>
          Responsable Explotación de los datos: BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL., representada por D. Adolfo Bartolomé Ibáñez Vila, con DNI no 44867848T. con domicilio sito en Calle General Sanmartín 7, puerta 11 46004 de Valencia y dirección de correo electrónico contacto@baukunst.es.


          </p>
          <p>
          Responsable de almacenamiento de los datos: BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL.., con domicilio en Calle General Sanmartín 7, puerta 11 46004 con CIF no B09737362, y dirección de correo electrónico contacto@baukunst.es


          </p>
          <p>
          En adelante, titulares de www.baukunst.es


          </p>
        </div>
        <h2>2. INFORMACIÓN Y CONSENTIMIENTO</h2>
        <div className="flex flex-col gap-y-2">
          <p>
          De conformidad con lo dispuesto en la Reglamento UE 679/2016 de Protección de Datos (RGPD) y en la Ley Orgánica 3/2018 de Protección de Datos Personales y garantía de derechos digitales (LOPDGDD), mediante la aceptación de la presente Política de Privacidad el interesado (en adelante, el “Interesado”) PRESTA SU CONSENTIMIENTO informado, expreso, libre e inequívoco para tratar los datos personales recabados a través del sitio web www.baukunst.es (en adelante, el “Sitio Web”), ya sea a través del los distintos formularios habilitados al efecto o bien a través de la información recabada a través de las cookies.


          </p>
          <p>
          Asimismo, el Interesado consiente que estos datos sean tratados por BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL.,para prestar sus servicios aplicando las medidas organizativas y de seguridad requeridas por el RGPD.


          </p>
          <p>
          El Interesado garantiza que los datos personales facilitados a “BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL.,” son ciertos y exactos, con lo que Hortatech no se hará responsable de cualquier incidencia derivada de la inexactitud o falsedad de la información proporcionada por los Interesados. El Interesado será responsable de cumplir con toda la normativa de protección de datos aplicable y acepta obtener todos los consentimientos, autorizaciones y/o aprobaciones legalmente necesarias antes de incluir datos personales en el Sitio Web. BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL., no puede garantizar la absoluta invulnerabilidad de los sistemas, por tanto, no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones que terceros puedan causar en los sistemas informáticos, documentos electrónicos o ficheros.
          </p>
        </div>

        <h2>3. FINALIDAD DEL TRATAMIENTO</h2>
        <div className="flex flex-col gap-y-2">
          <p>
          Los datos personales del Interesado o de terceros que sean cedidos por el Interesado serán tratados por BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL., únicamente para las siguientes finalidades:

</p>
<ul className="list-outside pl-4 list-disc space-y-3 *:space-y-1">

<li>Atender las solicitudes de información en el caso de datos recabados a través del formulario o email de contacto;
</li>
<li>
Llevar a cabo las funcionalidades del Sitio Web;

</li>
<li>

Dar cumplimiento a las obligaciones legalmente establecidas.
</li>
</ul>
        </div>
        <h2>4. LEGITIMACIÓN DEL TRATATAMIENTO</h2>
        <div className="flex flex-col gap-y-2">
          <p>
          Las bases legales legitimadoras de los tratamientos de datos que lleva a cabo BAUKUNST ARQUITECTURA Y PATRIMONIO VIRTUAL SL.,son las siguientes: contactar vía formulario, e-mail o chat online.


          </p>
          <h4>
          PROCEDENCIA
          </h4>
          <p>
          Del propio Usuario a través del formulario de contacto, de la ventana emergente del chat online en el Sitio Web o mediante el envío de correos electrónicos a la dirección indicada en el Sitio Web


          </p>
          <h4>
          BASE JURÍDICA
    </h4>
    <p>Reglamento General de Protección de Datos, en particular

Art. 6.1.a): consentimiento del Usuario ara uno o varios fines específicos.

</p>
<h4>FINES DEL TRATAMIENTO

</h4>
<p>Atender y responder las consultas solicitadas por el Usuario.

</p>

<h4>
COLECTIVO
</h4>

<p>

Personas físicas, incluidas las representantes de personas jurídicas, usuarios de el Sitio Web.
</p>

<h4>

CATEGORIA DE DATOS
</h4>

<p>

Nombre y apellidos y correo electrónico, así como el resto de datos que el propio Usuario indique en el cuerpo del mensaje o a través del chat online.
</p>

<h3>
ACTIVIDAD: NEWSLETTER
</h3>
<h4>PROCEDENCIA</h4>
<p>
                    Del propio Usuario a través del formulario de contacto, de la ventana emergente del chat online en el Sitio Web o mediante el envío de correos electrónicos a la dirección indicada en el Sitio Web.
                </p>
<h4>BASE JURÍDICA</h4>
<p>
                    Reglamento General de Protección de Datos, en particular
                </p>
<p>
                    Art. 6.1.a): consentimiento del Usuario ara uno o varios fines específicos.
                </p>
<h4>FINES DEL TRATAMIENTO</h4>
<p>
                    Envío de información en relación con las actualizaciones realizadas, servicios y publicaciones de Hortatech.
                </p>
<h4>COLECTIVO</h4>
<p>
                    Personas físicas, incluidas las representantes de personas jurídicas, usuarios de el Sitio Web.
                </p>
<h4>CATEGORIA DE DATOS</h4>
<p>
                    La dirección de correo electrónico.
                </p>
<h4>DESTINATARIOS</h4>
<p>
                    No está prevista la cesión de datos a terceros, salvo obligación legal o previo consentimiento inequívoco e informado del Usuario.
                </p>
<h4>TRANSFERENCIA INTERNACIONAL</h4>
<p>
                    No está prevista la transferencia internacional de datos.
                </p>
<h4>PLAZOS DE CONSERVACIÓN</h4>
<p>
                    Los datos serán conservados hasta que el Usuario retire su consentimiento o haya acabado la finalidad para la que fueron recabados, salvo que los datos deban mantenerse por una obligación legal.
                </p>

        </div>

        <h2>5. DATOS DEL USUARIO</h2>
        <div className="flex flex-col gap-y-2">
        <p>
                    Hortatech no puede garantizar la absoluta invulnerabilidad de los sistemas, por tanto, 
                    no asume ninguna responsabilidad por los daños y perjuicios derivados de alteraciones 
                    que terceros puedan causar en los sistemas informáticos, documentos electrónicos o 
                    ficheros.
                </p>
<p>
                    El Usuario garantiza que los datos aportados son verdaderos, exactos, completos y 
                    actualizados, siendo éste responsable de cualquier daño o perjuicio, directo o indirecto, 
                    que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación, más 
                    allá de la posibilidad que tiene el Usuario de ejercitar su derecho de rectificación.
                </p>
<p>
                    En el caso de que los datos aportados pertenecieran a un tercero, el Usuario garantiza 
                    que ha informado a dicho tercero de los aspectos contenidos en este documento y 
                    obtenido su autorización para facilitar sus datos al responsable para los fines 
                    anteriormente señalados.
                </p>
        </div>

        <h2>6. POLÍTICA DE COOKIES</h2>

        
            <p>

            De conformidad con lo dispuesto por el RGPD y la Ley 34/2002, de Servicios de la Sociedad de la Información, todos los datos de carácter personal obtenidos mediante las cookies durante la utilización del Sitio Web serán tratados de conformidad con los dispuesto en la Política de Cookies
            </p>
        <h2>7. EJERCICIO DE DERECHOS

</h2>
        <div className="flex flex-col gap-y-2">
        <p>
                    De conformidad con lo dispuesto en el RGPD, le informamos que puede ejercer sus derechos de acceso, rectificación, supresión, oposición, portabilidad de los datos y limitación del tratamiento directamente ante Hortatech.
                </p>
<p>
                    Para facilitar el ejercicio de dichos derechos, el Usuario podrá acceder online al siguiente formulario de ejercicio de derechos, debiendo atenderse la solicitud en el plazo máximo de un (1) mes desde la recepción del formulario, o bien podrá enviar un mensaje al efecto, indicando nombre, apellidos, email, número de DNI o pasaporte, a la siguiente dirección de correo electrónico: hola@hortatech.es
                </p>
<p>
                    El Usuario también podrá ejercer sus derechos mediante la presentación de una reclamación ante la Agencia Española de Protección de Datos.
                </p>
        </div>

        <h2>8. ACEPTACIÓN DE LA POLÍTICA DE PRIVACIDAD

</h2>
        <div className="flex flex-col gap-y-2">
        <p>
                    El Usuario RECONOCE Y ACEPTA HABER LEÍDO Y COMPRENDIDO la presente Política de Privacidad cuyo contenido constituye la totalidad del acuerdo entre el Usuario y Hortatech con respecto al uso y tratamiento de su información personal.
                </p>
<p>
                    El Usuario expresamente acepta obligarse en los términos de la presente Política de Privacidad, en toda su extensión y alcance, sin exceptuar cualquiera de sus disposiciones
                </p>
<p>28 de junio de 2023.</p>
        </div>
        
      </div>
    </section>
  );
};

export default PrivacyPolicy;
