import { ReactNode, useState } from "react";
import Icon from "../Icon";

export default function SwitchableFeatureShowcase({
  features,
  children,
}: {
  features: {
    title: string;
    subtitle?: string | undefined;
    image: string;
    description: string;
    icon: string;
  }[];
  children?: ReactNode;
}) {
  const [selectedFeature, setSelectedFeature] = useState(0);
  const selected = features[selectedFeature]; // This is the feature currently selected

  return (
    <div className="flex flex-col gap-6 lg:items-center">
      {children}
      <div className="grid grid-cols-1 w-full lg:grid-cols-[minmax(auto,1fr),_50%] md:gap-x-20  grid-rows-2 items-start gap-8">
        <div className="flex row-span-2 snap-x snap-mandatory overflow-x-auto flex-row gap-6 md:grid md:grid-cols-2 md:grid-rows-2">
          {features.map((f, i) => (
            <button
              onClick={() => {
                setSelectedFeature(i);
              }}
              className={`group shrink-0 snap-start w-2/3 md:w-full rounded-md transition-colors text-left lg:py-4 px-4 py-2 items-start md:items-center flex md:flex-row flex-col gap-4 ${
                i === selectedFeature
                  ? "bg-gray-800 text-white hover:bg-gray-700 lg:text-white dark:hover:bg-gray-600 dark:bg-gray-700"
                  : "bg-gray-100 text-black hover:bg-gray-200 dark:hover:bg-gray-800 dark:bg-gray-900 dark:text-white"
              }`}>
              <Icon
                name={f.icon}
                primaryColor={i === selectedFeature ? "blue-100" : "blue-500"}
                secondaryColor={i === selectedFeature ? "blue-500" : "blue-900"}
                darkSecondaryColor={
                  i === selectedFeature ? "blue-600" : "blue-300"
                }
                className={`mt-1 size-8 shrink-0 lg:size-12 dark:filter ${
                  i !== selectedFeature ? "dark:brigh tness-200" : ""
                }`}
              />
              <div className="flex flex-col">
                <span className="font-semibold lg:mb-1">{f.title}</span>
                {f.subtitle && <span className="text-sm">{f.subtitle}</span>}
              </div>
            </button>
          ))}
        </div>
        {selected.image && (
          <div className="flex w-full h-full  justify-start ">
            <div className="w-fit flex h-52 md:h-80 aspect-[2/1] ">
              <img
                src={selected.image}
                alt="Featured"
                className="w-full h-full  md:rounded-3xl object-[30%,_40%] object-cover rounded-xl shadow-lg lg:shadow-2xl"
              />
            </div>
          </div>
        )}

        <span className="text-left md:px-4 p-2 mb-2">
          {selected.description}
        </span>
      </div>
    </div>
  );
}
