import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "components/ui/accordion";
import Icon from "components/Icon";

const AnimatedAccordionItem = ({
  items,
}: {
  items: { title: string; content: string; icon: string }[];

}) => {
  const { ref, inView } = useInView({
    threshold: 0.4, // Adjust threshold as needed
    triggerOnce: true, // Optionally set to true if you want the animation to play only once
  });
  const [animation, setAnimation] = useState("");

  useEffect(() => {
    if (inView) {
      setAnimation("animate-growIn"); // Play animation when in view
    } 
  }, [inView]);

  return (
    <Accordion
    ref={ref}
        defaultValue="item-1"
        className="overflow-clip gap-y-2 flex flex-col"
        type="single"
        collapsible>
            {items.map((item, index) => (
              <AccordionItem
              key={index}
              style={{animationDuration: "0.3s" , animationDelay: `${index * 0.15}s` }}
              className={`*:overflow-visible overflow-clip opacity-0 hover:bg-green-100 dark:hover:bg-green-700 transition-all border border-gray-200 dark:border-green-800 data-[state=open]:border-green-200 dark:data-[state=open]:border-green-900 data-[state=open]:shadow-sm md:py-2 rounded-lg px-4 md:px-8 data-[state=closed]:mx-4 data-[state=open]:pr-8 md:data-[state=open]:pr-12 data-[state=open]:bg-green-200 dark:data-[state=open]:bg-green-900 ${animation}`}
              value={`item-${index + 1}`}>
      <AccordionTrigger className="text-lg decoration-transparent ">
        {item.title}
      </AccordionTrigger>
      <AccordionContent  className="flex ">
        {item.content}
        <div className="h-20 scal">
          <Icon
            primaryColor="green-300"
            secondaryColor="green-400"
            className=" translate-x-1/2 z-10 scale-150"
            name={item.icon}
            />
        </div>
      </AccordionContent>
    </AccordionItem>
          ))}
    </Accordion>

  );
};

export default AnimatedAccordionItem;
