import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import CardItemWithIcon from "components/molecules/CardItemWithIcon";
import { CardItemType } from "lib/types"; // Import your type definition
// import "./styles.css"
interface MonitoredCardProps {
    card: CardItemType; // Use the imported type
}

// New component for each card item, now typesafe
const MonitoredCard: React.FC<MonitoredCardProps> = ({ card }) => {
    const { ref, inView } = useInView({
        // triggerOnce: true,
        threshold: 0.5  // Trigger when 50% of the element is in view
    });
    const [animation, setAnimation] = useState('');

    useEffect(() => {
        if (inView) {
            setAnimation('fly-in-from-bottom');
        } else {
            setAnimation('fly-out-to-top');
        }
        
    }, [inView]);

    console.log("is in view: ", inView)

    return (
        <div ref={ref} className={`relative row-span-2 z-10`}>
            <CardItemWithIcon
                title={card.title}
                content={card.content}
                boxClass={`bg-gray-100 h-full duration-500 ${animation} m b-2  [transform:trans lateZ(0)]`}
            />
        </div>
    );
};

export default MonitoredCard;
