import React, { useState } from "react";
import HDScreenshot from "assets/img/hd-screenshot.png";
import { Button } from "components/ui/button";

interface Section {
  key: string;
  text: string;
  title: string;
  className: string; // This will apply specific styles to each section
}
const DashboardSwitcher: React.FC = () => {
  const [activeSection, setActiveSection] = useState<Section | null>(null);

  const sections: Section[] = [
    {
      key: "control-automatizado",
      text: "Los sistemas de control en tiempo real permiten ajustar automáticamente los flujos de agua y los ciclos de riego en función de las necesidades reales de los cultivos y las condiciones climáticas. Esto garantiza que se suministre la cantidad de agua adecuada en el momento adecuado, evitando tanto el desperdicio como la escasez de agua.",
      title: "Control Automatizado",
      className: "left-[13.4%] top-[11.2%]  w-[42.4%] h-[42.9%]",
    },
    {
      key: "alertas-alarmas",
      text: "Los sistemas de monitoreo en tiempo real están equipados con alertas y alarmas que se activan cuando se detectan condiciones anormales, como fugas de agua, niveles bajos de agua o problemas de calidad del agua. Estas alertas permiten una respuesta rápida y eficiente, evitando pérdidas significativas y minimizando el impacto en los cultivos y el entorno.",
      title: "Alertas y Alarmas",
      className: "right-[0.8%] top-[11.2%]  w-[42.4%] h-[42.9%]",
    },
    {
      key: "visualización",
      text: "Los datos recopilados por los sistemas de monitoreo en tiempo real se presentan en paneles de control intuitivos y visuales. Esto permite a los gestores de agua y agricultores acceder a información clara y comprensible sobre el estado de los recursos hídricos.",
      title: "Análisis de Datos",
      className: "left-[13.4%] bottom-[1.55%]  w-[42.4%] h-[42.9%]",
    },
    {
      key: "sensores-estaciones",
      text: "Se instalan sensores en diferentes puntos estratégicos de la Huerta de Valencia, como fuentes de agua, canales de riego y campos de cultivo. Estos sensores recopilan datos sobre el nivel de agua, la calidad del agua, la temperatura y otros parámetros relevantes.",
      title: "Mapa GIS",
      className: "right-[0.8%] bottom-[1.55%]  w-[42.4%] h-[42.9%]",
    },
  ];

  const toggleSection = (section: Section) => {
    if (activeSection !== null && activeSection.key === section.key) {
      setActiveSection(null);
      return;
    } else {
      setActiveSection(activeSection === section ? null : section);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-2 gap-2 md:grid-cols-4 w-full justify-between">
        {sections.map((section) => (
          <Button
            key={section.key}
            className="w-full"
            size="lg"
            onClick={() => toggleSection(section)}
            title={section.title}>
            {section.title}
          </Button>
        ))}
      </div>
      <div className="mockup-browser shadow-lg md:shadow-xl rounded-2xl overflow-x-auto overflow-hidden relative border bg-gray-50">
        <div className="mockup-browser-toolbar">
          <div className="input text-sm flex justify-center items-center text-center text-gray-900 bg-gray-200 rounded-md">
            <span>hortatech.es/dashboard</span>
          </div>
        </div>
        <div className="flex justify-center  bg-base-200">
          <div className="relative ">
            {/* Conditional rendering of sections */}
            {activeSection && (
              <div
                className={`absolute transition-all bg-transparent rounded-md backdrop-filter z-10 outline outline-1 outline-gray-600 backdrop-brightness-[1.3] ${activeSection.className}`}></div>
            )}
            {activeSection && (
              <div className="absolute inset-0 backdrop backdrop-brightness-[0.85]"></div>
            )}
            <img
              src={HDScreenshot}
              alt="Huerta de Valencia"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
      <div className="px-8 py-4 min-h-36">
        {activeSection && (
          <>
            <h4>{activeSection.title}</h4>
            <p>{activeSection.text}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default DashboardSwitcher;
