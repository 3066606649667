import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import "./tabs.css"; // Assuming you have a styles.css file for animations
import { Link } from "react-router-dom";
import { Button } from "components/ui/button";
export interface TabsItem {
  icon: string;
  key: string;
  name: string;
  content: string;
  link?: string;
}



const AnimatedTabs = ({items}:{items: TabsItem[] }) => {
  return (

    <Tabs defaultValue={items[0].key} className="w-full">
      <TabsList  className="h-28 w-full gap-x-2 p-0 bg-transparent dark:bg-transparent">
        {items.map((item) => (
          <TabsTrigger
            className="h-full hover:bg-yellow-50 dark:data-[state=inactive]:bg-yellow-600 dark:data-[state=active]:hover:bg-yellow-300 dark:data-[state=inactive]:hover:bg-yellow-300 data-[state=active]:bg-yellow-100 dark:data-[state=active]:bg-yellow-200 data-[state=active]:shadow-transparent w-full"
            key={item.key}
            value={item.key}>
            <div className="p-4 h-full flex items-center select-none">
              <img className="min-h-4 max-h-24" src={item.icon}></img>
            </div>
          </TabsTrigger>
        ))}
      </TabsList>
      {items.map((item) => (
        <TabsContent className="bg-yellow-100 dark:bg-yellow-200 dark:text-black rounded-md  p-6" key={item.key} value={item.key}>
          <div className="flex h-f items-center flex-col">
            <h4>{item.name}</h4>
            <p>{item.content}</p>
            {item.link &&
            <Button variant="link" className="ml-auto opacity-70 hover:opacity-100" asChild>

            <Link target="_blank" className="dark:text-black" to={item.link}>
              Aprender mas...
            </Link>
            </Button>
            }
          </div>
        </TabsContent>
      ))}
    </Tabs>
  );
};

export default AnimatedTabs;

// const AnimatedTabs = () => {
//   return (
//     <Tabs defaultValue="lasnaves" className="w-full">
//       <TabsList className="h-28 w-full p-2">
//         {TabsItems.map((item) => (
//           <TabsTrigger
//             className="h-full w-full"
//             key={item.key}
//             value={item.key}>
//             <div className="p-4 h-full  flex items-center select-none">
//               <img className="min-h-4" src={item.icon}></img>
//             </div>
//           </TabsTrigger>
//         ))}
//       </TabsList>
//       {TabsItems.map((item) => (
//         <TabsContent key={item.key} value={item.key}>
//           <div className="flex h-f items-center flex-col">
//             <h4>{item.name}</h4>
//             <p>{item.content}</p>
//           </div>
//         </TabsContent>
//       ))}
//     </Tabs>
//   );
// };