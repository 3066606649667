export default function ThreePointShowCase({ items }: { items: { title: string, image: string, content: string }[] }) {
	return (

			<div className="flex flex-col md:flex-row  gap-6">
			{
				items.map((i) => 
				<div className="basis-1/3">
					<img src={i.image} alt="Showcase image" className="rounded-xl mb-4 aspect-video lg:aspect-video md:aspect-square object-cover lg:h-1/2 max-h-48" />
					<h4 className="text-lg font-semibold">{i.title}</h4>
					<p>{i.content}</p>
				</div>
				)
			}
			</div>
	)
}