import { ReactNode } from "react";

export default function CarouselCard({ title, content, header, variant = "simple", isActive }: { title: string, content: string, header: ReactNode, variant?: "simple" | "active-indicator" | "blurred-bg", isActive: boolean }) {
	const styles = {
		root: {
			"active-indicator": `relative flex flex-col items-center dark:bg-gray-900 rounded-xl bg-gray-100 overflow-clip h-full`,
			"simple": "rounded-xl bg-white dark:bg-gray-900 overflow-clip h-full"	,
			"blurred-bg": "rounded-xl bg-white/75 dark:bg-gray-900/75 backdrop-blur-xl overflow-clip h-full shadow-lg dark:shadow-2xl",
		},
		title: {
			"active-indicator": ' p-5 text-center',
			"simple": " p-5",
			"blurred-bg": " p-5",
		},
		content: {
			"active-indicator": 'px-5 pb-4 text-center',
			"simple": "px-5 pb-4",
			"blurred-bg": "px-5 pb-4",
		}
	} as const;
	
	return (
		<div className={styles.root[variant]}>
			<div className={variant === "active-indicator" ? `transition-[border-width] duration-[0.2s] ease-in-out absolute inset-0 border-b-red-600 dark:border-b-red-800 ${isActive ? "border-b-8" : "border-b-0"}` : undefined}></div>
			{header}
			<h4 className={styles.title[variant]}>{title}</h4>
			<p className={styles.content[variant]}>{content}</p>
		</div>
	)
}