import { useRef, useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { CSSTransition } from "react-transition-group";
import HDScreenshot from "assets/img/hd-screenshot.png";

import Icon from "components/Icon";
import { Button } from "components/ui/button";
import SensorTechnology from "pages/Technology/SensorTechnology";
import { Icon as IconifyIcon } from "@iconify/react";

export default function TabbedFourPointShowcase({
  tabs,
}: {
  tabs: {
    name: string;
    items: { title: string; content: string; icon: string }[];
  }[];
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const animationPlayed = useRef(new Array(tabs.length).fill(false));

  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    // console.log("inView animationsPlayed", animationPlayed);
    // console.log(
    //   "inView",
    //   inView,
    //   "selectedTab",
    //   selectedTab,
    //   "played animation",
    //   animationPlayed.current[selectedTab]
    // );
    if (inView && !animationPlayed.current[selectedTab]) {
      const newAnimations = [...animationPlayed.current];
      newAnimations[selectedTab] = true; // Set the current tab as animated
      animationPlayed.current = newAnimations;
    }
    // console.log(
    //   "inView after",
    //   inView,
    //   "selectedTab",
    //   selectedTab,
    //   "played animation",
    //   animationPlayed.current[selectedTab]
    // );
  }, [inView, selectedTab]);

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };
  return (
    <div className="flex flex-col items-center gap-6">
      <div className="mb-4 flex flex-row bg-transparent scroll-px-8 w-auto px-4 md:px-0 mx-auto snap-x snap-mandatory space-x-3 overflow-x-scroll hide-scrollbar ">
        {tabs.map((t, i) => (
          <Button
            variant="ghost"
            className={`rounded-full snap-start ${
              i === selectedTab
                ? "  bg-gray-500 text-secondary dark:text-secondary"
                : "  shrink-0 transition-all "
            }`}
            onClick={() => handleTabChange(i)}>
            {t.name}
          </Button>
        ))}
      </div>
      <div
        ref={ref}
        className="grid grid-cols-1 min-h-96 grid-rows-1 *:w-full w-full *:row-span-full *:col-span-full">
        {tabs.map((tab, index) => (
          <CSSTransition
            in={selectedTab === index}
            timeout={300}
            classNames="tab-content"
            unmountOnExit>
            <div key={index}>
              {index === 0 && (
                <InnovativeApproach
                  shouldAnimate={animationPlayed.current[index]}
                />
              )}
              {index === 1 && (
                <OpticFiberSensors
                  shouldAnimate={animationPlayed.current[index]}
                />
              )}
              {index === 2 && (
                <ControlPanel shouldAnimate={animationPlayed.current[index]} />
              )}
            </div>
          </CSSTransition>
        ))}
      </div>
     
    </div>
  );
}

const InnovativeApproach = ({ shouldAnimate }: { shouldAnimate: any }) => {
  // console.log("inView shouldAnimate", shouldAnimate)
  return (
    <div
      className={`${
        shouldAnimate ? "" : "animate-slide-out-to-left"
      } grid w-full *:flex  *:flex-col *:items-center *:justify-center grid-rows-[repeat(3,_auto)] grid-cols-4 gap-8`}>
      <div>
        <div className="relative">
          <Icon
            name="tech-new-manual"
            className={`size-20  ${
              shouldAnimate ? "animate-fadeInHalfOut opacity-0" : ""
            }`}
            primaryColor="green-500"
            secondaryColor="gray-100"
          />
          <IconifyIcon
            icon="bi:slash-lg"
            className={`size-20 p-2 absolute inset-0 !animation-delay-600 ${
              shouldAnimate ? "animate-fadeIn opacity-0" : ""
            }`}
          />
        </div>
        <p>Manual</p>
      </div>
      <div>
        <div className="relative">
          <Icon
            name="tech-new-point"
            className={`size-20 !animation-delay-100 ${
              shouldAnimate ? "animate-fadeInHalfOut opacity-0" : ""
            }`}
            primaryColor="green-500"
            secondaryColor="gray-100"
          />
          <IconifyIcon
            icon="bi:slash-lg"
            className={`size-20 p-2 absolute inset-0 !animation-delay-900 ${
              shouldAnimate ? "animate-fadeIn opacity-0" : ""
            }`}
          />
        </div>
        <p>Control puntual</p>
      </div>
      <div>
        <div className="relative">
          <Icon
            name="tech-new-correct"
            className={`size-20 !animation-delay-200 ${
              shouldAnimate ? "animate-fadeInHalfOut opacity-0" : ""
            }`}
            primaryColor="green-500"
            secondaryColor="gray-100"
          />
          <IconifyIcon
            icon="bi:slash-lg"
            className={`size-20 p-2 absolute inset-0 !animation-delay-1200 ${
              shouldAnimate ? "animate-fadeIn opacity-0" : ""
            }`}
          />
        </div>
        <p>Correctivo</p>
      </div>

      <div>
        <div className="relative">
          <Icon
            name="tech-new-hidden"
            className={`size-20 !animation-delay-300 ${
              shouldAnimate ? "animate-fadeInHalfOut opacity-0" : ""
            }`}
            primaryColor="green-500"
            secondaryColor="gray-100"
          />
          <IconifyIcon
            icon="bi:slash-lg"
            className={`size-20 p-2 absolute inset-0 !animation-delay-1500 ${
              shouldAnimate ? "animate-fadeIn opacity-0" : ""
            }`}
          />
        </div>
        <p className="">Opaca</p>
      </div>
      <div
        className={` !animation-delay-600 ${
          shouldAnimate ? "animate-fly-in-from-top opacity-0" : ""
        }`}>
        <Icon
          name="arrow"
          className="size-16 -rotate-90"
          primaryColor="green-400"
        />
      </div>
      <div
        className={` !animation-delay-900 ${
          shouldAnimate ? "animate-fly-in-from-top opacity-0" : ""
        }`}>
        <Icon
          name="arrow"
          className="size-16 -rotate-90"
          primaryColor="green-400"
        />
      </div>
      <div
        className={` !animation-delay-1200 ${
          shouldAnimate ? "animate-fly-in-from-top opacity-0" : ""
        }`}>
        <Icon
          name="arrow"
          className="size-16 -rotate-90"
          primaryColor="green-400"
        />
      </div>
      <div
        className={`!animation-delay-1500 ${
          shouldAnimate ? "animate-fly-in-from-top opacity-0" : ""
        }`}>
        <Icon
          name="arrow"
          className="size-16 -rotate-90"
          primaryColor="green-400"
        />
      </div>

      <div
        className={` !animation-delay-600 ${
          shouldAnimate ? "animate-growIn opacity-0" : ""
        }`}>
        <Icon
          primaryColor="green-500"
          secondaryColor="gray-100"
          name="tech-new-digital"
          className="size-24"
        />
        <p className="">Digital</p>
      </div>
      <div
        className={` !animation-delay-900 ${
          shouldAnimate ? "animate-growIn opacity-0" : ""
        }`}>
        <Icon
          primaryColor="green-500"
          secondaryColor="gray-100"
          name="tech-new-all-network"
          className="size-24"
        />
        <p className="">Una red conectada</p>
      </div>
      <div
        className={` !animation-delay-1200 ${
          shouldAnimate ? "animate-growIn opacity-0" : ""
        }`}>
        <Icon
          primaryColor="green-500"
          secondaryColor="gray-100"
          name="tech-new-predict"
          className="size-24"
        />
        <p className="">Predictivo</p>
      </div>
      <div
        className={` !animation-delay-1500 ${
          shouldAnimate ? "animate-growIn opacity-0" : ""
        }`}>
        <Icon
          primaryColor="green-500"
          secondaryColor="gray-100"
          name="tech-new-transparent"
          className="size-24"
        />
        <p className="">Transparente</p>
      </div>
    </div>
  );
};

const OpticFiberSensors = ({ shouldAnimate }: { shouldAnimate: any }) => {
  return (
    <div className="pb-12">
      <SensorTechnology />
    </div>
  );
};

const ControlPanel = ({ shouldAnimate }: { shouldAnimate: any }) => {
  
  return (
    <div className="grid grid-flow-col grid-rows-2 *:items-center *:justify-center grid-cols-[auto,1fr,auto] gap-4">
      <div className={`relative flex w-full  ${
              shouldAnimate ? "animate-slide-in-from-right opacity-0" : ""
            }`} >
        <span className="block ml-auto mr-2 ">Control Automatizado</span>
        <div className="h-px w-40 left-full absolute bg-gray-400"></div>
      </div>
      <div className={`relative flex w-full !animation-delay-300 ${
              shouldAnimate ? "animate-slide-in-from-right opacity-0" : ""
            }`} >
        <span className="block ml-auto mr-2 ">Alertas y Alarmas</span>
        <div className="h-px w-40 left-full absolute bg-gray-400"></div>
      </div>
      <div className="mx-10 z-10 relative row-span-2 rounded-lg shadow-orange-500 shadow-lg md:shadow-xl overflow-clip">
        <img
          src={HDScreenshot}
          alt="Huerta de Valencia"
          className="w-full h-full object-cover"
        />
      </div>
      <div className={`relative flex w-full !animation-delay-600 ${
              shouldAnimate ? "animate-slideInFromLeft opacity-0" : ""
            }`} >
        <span className="block mr-auto ml-2">Análisis de Datos</span>
        <div className="h-px w-40 right-full absolute bg-gray-400"></div>
      </div>
      <div className={`relative flex w-full !animation-delay-900 ${
              shouldAnimate ? "animate-slideInFromLeft opacity-0" : ""
            }`} >
        <span className="block mr-auto ml-2">Mapa GIS</span>
        <div className="h-px w-40 right-full absolute bg-gray-400"></div>
      </div>
    </div>
  );
};
