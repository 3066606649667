import { useState } from 'react';
import { Button } from 'components/ui/button';
import Icon from 'components/Icon';

import {ReactComponent as DiaphragmaDiagram} from './diagramas/diagrama-diaphragma.svg';
import {ReactComponent as FiberOpticDiagram} from './diagramas/diagrama-fibra.svg';
import {ReactComponent as PressureDiagram} from './diagramas/diagrama-presion.svg';
import {ReactComponent as TemperatureDiagram} from './diagramas/diagrama-temperatura.svg';

const SensorTechnology = () => {
  const items = [
    {
      title: 'Sensores de fibra optica',
      icon: 'sensor-zoom',
      key: 'optic-fiber',
      image: FiberOpticDiagram, // Assuming contaminant detection uses diaphragm technology
      description: 'Usamos tecnología moderna de fibra óptica para hacer todas las mediciones. Esto nos permite tener una precisión y velocidad de respuesta inigualable.'
    },
    {
      title: 'Medición de Caudal',
      icon: 'measure-water-flow',
      key: 'water-flow',
      image: DiaphragmaDiagram, // FIX IT LATER; ADD ICON
      description: 'El caudal se mide con un diafragma que se deforma con el flujo de agua. La deformación se mide con un sensor de fibra óptica. Esto nos permite medir el flujo de agua con precisión.'
    },
    {
      title: 'Medición de Presión',
      icon: 'time-measurement-simple',
      key: 'pressure',
      image: PressureDiagram,
      description: 'La presión se mide con un sensor de fibra óptica. La deformación de una membrana se mide con un sensor de fibra óptica. Esto nos permite medir la presión con precisión.'
    },
    {
      title: 'Medición de Temperatura',
      icon: 'measure-temperature',
      key: 'temperature',
      image: TemperatureDiagram,
      description: 'La temperatura se mide por el cambio de longitud de una fibra óptica. Esto nos permite medir la temperatura con precisión.'
    },
  ];

  const [selectedItem, setSelectedItem] = useState(items[0]);

  return (
    <div className="grid grid-rows-[auto,_1fr] md:grid-rows-1 md:grid-cols-[auto,_1fr] gap-4 bg-gray-800 rounded-xl border-gray-200/50 border shadow-xl">
      <div className="p-2 flex flex-col gap-2 border-r border-gray-500">
        {items.map((item) => (
          <Button
            variant="default"
            className={`${selectedItem.key === item.key ? "bg-gray-100  hover:bg-gray-200 text-gray-900" : "bg-gray-700 dark:bg-gray-950 dark:text-gray-200 dark:hover:bg-gray-600 hover:bg-gray-600 text-white" } text-left  h-auto flex justify-start items-center gap-x-4`}
            key={item.key}
            onClick={() => setSelectedItem(item)}
          >
            <Icon
              name={item.icon}
              primaryColor={selectedItem.key === item.key ? "green-900" : "green-100"}
              secondaryColor="green-500"
              className="size-6"
            />
            {item.title}
          </Button>
        ))}
      </div>
      <div className="p-4 flex flex-col gap-2">
        {/* {selectedItem.image} */}
        {selectedItem.image && <selectedItem.image className='text-green-500 w-full h-auto' />}
        {/* <img
          src={selectedItem.image}
          className="rounded-md text-green-300 w-full h-auto"
          alt={`Technology image for ${selectedItem.title}`}
        /> */}
        <p className="text-sm text-gray-300">
          {selectedItem.description}
        </p>
      </div>
    </div>
  );
};

export default SensorTechnology;
