import React, { useState } from "react";

import { Button } from "components/ui/button";
import ScreenshotMobile2 from "assets/img/technology/screenshot-mobile-2.png";
import ScreenshotMobileCheckIn from "assets/img/technology/screenshot-mobile-check-in.png";
import ScreenshotMobileSimpleOverview from "assets/img/technology/screenshot-mobile-simple-overview.webp";
import ScreenshotMobile from "assets/img/technology/screenshot-mobile-1.png";
import PhoneComponent from "components/molecules/PhoneComponent";
// import ScreenshotPhone1 from "assets/img/technology/screenshot-phone-1.webp";
const PhoneSwitcher = () => {

  type Category = {
    key: string;
    title: string;
    description: string;
    image: string;
  };

  

  const phoneItems: Category[] = [
    {
      key: "manage-times",
      title: "Gestión de tiempos",
      description: "En esta sección el usuario puede gestionar los tiempos de riego de su sistema de riego. Puede añadir, editar y eliminar sus tiempos de riego.",
      image: ScreenshotMobile2,
    },
    {
      key: "acceso-rapido",
      title: "Acceso rápido",
      description:
        "Con la aplicación web el usuario puede acceder a todas las funcionalidades del panel de control desde cualquier dispositivo. Aprovecha el acceso rápido para acceder a la gestión de su sistema de tiempo.",
      image: ScreenshotMobileSimpleOverview,
    },
    {
      key: "check-in",
      title: "Check In",
      description: "Cada día de riego, el usuario puede realizar un check-in para confirmar que ha revisado el sistema de riego y que todo está en orden.",
      image: ScreenshotMobileCheckIn,
    },
    {
      key: "cat2",
      title: "Planificación simple",
      description: "Durante la semana, ocasionalmente el sistema le pregunta al usuario si va a regar el proximo día. Si el usuario confirma, el sistema planifica el riego para el día siguiente.",
      image: ScreenshotMobile,
    },
  ];

  const [selectedPhoneKey, setSelectedPhoneKey] = useState<string | null>("acceso-rapido");

  const filterPhone = (category: Category): void => {
    setSelectedPhoneKey(category.key); // Update selected category based on key
  };
  
  const selectedPhoneItem = phoneItems.find(
    (category) => category.key === selectedPhoneKey
  );
  


  
  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-[auto,_1fr]">
            <div className=" flex flex-row my-4 bg-gray-700 md:bg-transparent scroll-px-8 w-screen md:w-full -ml-4 md:ml-0 md:flex-col md:max-w-[100%] mx-auto snap-x md:snap-y snap-mandatory gap-3 overflow-scroll p-2 px-4 hide-scrollbar">
              {phoneItems.map((category) => (
                <Button
                  variant="ghost"
                  key={category.key}
                  className={`snap-center justify-start  px-6 rounded-full shrink-0 ${
                    selectedPhoneKey === category.key
                      ? "  bg-gray-500 text-secondary dark:text-secondary"
                      : "   transition-all "
                  }`}
                  onClick={() => filterPhone(category)}>
                  {category.title}
                </Button>
              ))}
            </div>
            {selectedPhoneItem && (
              <div className="p-4 grid md:grid-cols-[auto,_1fr] grid-rows-[auto,_1fr] grid-cols-1 md:grid-rows-1 items-center gap-4 ">
                {/* Phone mockup */}
                <PhoneComponent image={selectedPhoneItem.image} alt={selectedPhoneItem.title} />
                {/* <div className="relative mx-auto  border-gray-800 dark:border-gray-800 bg-gray-800 border-[12px] transition-all md:border-[14px] rounded-[2.5rem] h-[480px] w-[240px] md:h-[600px] md:w-[300px] shadow-xl">
                  <div className="transition-all w-[118px] h-[15px] md:w-[148px] md:h-[18px]  bg-gray-800 top-0 rounded-b-[1rem] left-1/2 -translate-x-1/2 absolute"></div>
                  <div className="transition-all h-[37px] w-[3px] md:h-[46px] md:w-[3px] bg-gray-800 absolute -start-[15px] md:-start-[17px] top-[100px] md:top-[124px] rounded-s-lg"></div>
                  <div className="transition-all h-[37px] w-[3px] md:h-[46px] md:w-[3px] bg-gray-800 absolute -start-[15px] md:-start-[17px] top-[142px] md:top-[178px] rounded-s-lg"></div>
                  <div className="transition-all h-[51px] w-[3px] md:h-[64px] md:w-[3px] bg-gray-800 absolute -end-[15px] md:-end-[17px] top-[142px] rounded-e-lg"></div>
                  <div className="transition-all rounded-[2rem] overflow-hidden w-[218px] h-[456px] md:w-[272px] md:h-[572px] bg-white dark:bg-gray-800">
                    <img
                      src={selectedPhoneItem.image}
                      alt={selectedPhoneItem.title}
                      className="transition-all rounded-t-lg w-[218px] md:w-[272px] h-[456px] md:h-[572px]"
                    />
                  </div>
                </div> */}
                <div className="p-2">
                  <p className="text-gray-200 dark:text-gray-300">
                    {selectedPhoneItem.description}
                  </p>
                </div>
              </div>
            )}
          </div>
  );
}

export default PhoneSwitcher;

