import React, { useEffect, useState, forwardRef } from "react";

import Screenshot from "assets/img/hd-screenshot.webp";
import ScreenshotDark from "assets/img/hd-screenshot-dark.webp";
import { Icon } from "@iconify/react";
import { Button } from "components/ui/button";
import BrowserWindowComponent from "components/molecules/BrowserWindowComponent";
import { HashLink } from "react-router-hash-link";

interface HeroProps {
  scrollPosition: number;
}

const Hero = forwardRef<HTMLElement, HeroProps>(({ scrollPosition }, ref) => {
  
  const initialState = {
    scale: 1,
    skew: 0,
    translateY: 0,
  };

  const [showVideo, setShowVideo] = useState(false);

  const maxValues = {
    scale: 0.8,
    skew: 0.0004,
    translateY: -100,
  };

  const interpolate = (start: number, end: number, factor: number) => start + (end - start) * factor;

  const [transformValue, setTransformValue] = useState<string>(
    `matrix3d(${initialState.scale},0,0,0,0,${initialState.scale},0,${-initialState.skew},0,0,1,0,0,${initialState.translateY},0,1)`
  );

  useEffect(() => {
    const scale = interpolate(initialState.scale, maxValues.scale, scrollPosition);
    const skew = interpolate(initialState.skew, maxValues.skew, scrollPosition);
    const translateY = interpolate(initialState.translateY, maxValues.translateY, scrollPosition);

    setTransformValue(`matrix3d(${scale},0,0,0,0,${scale},0,${-skew},0,0,1,0,0,${translateY},0,1)`);
  }, [scrollPosition, initialState.scale, initialState.skew, initialState.translateY, maxValues.scale, maxValues.skew, maxValues.translateY]);

  
// const transformValue = `matrix3d(${0.8 + scrollPosition * 0.2}, 0, 0, 0, 0, ${0.8 + scrollPosition * 0.2}, 0, -${0.0005 + scrollPosition * 0.001}, 0, 0, 1, 0, 0, -${60 * (1 - scrollPosition)}, 0, 1)`;


  return (
    <>
      <div className="container z-10 px-4 mx-auto relative mb-10 flex items-start h-fit mt-10 md:mt-28">
        <div className="text-start md:text-center md:mb-10 ">
          <div className="flex flex-col gap-y-2 justify-center md:items-center h-full">
            <h1 className="text-balance !leading-tight mb-6 md:max-w-[28ch]">
              Innovación y Sostenibilidad  para un Futuro mejor
            </h1>
            <div className="relative ">

            <h2 className="text-lg max-w-[45ch] text-balance font-light leading-normal text-gray-200">
              Optimizando la Gestión del Agua y Preservando el Patrimonio de la
              Huerta de Valencia
            </h2>
            </div>
            <div className="flex  gap-5 mt-6 items-start md:items-center">
              <Button asChild variant="accent" size="lg" className="text-base px-4 md:px-6">
                <HashLink
                smooth
                scroll={(el) =>
                  el.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  })
                }
                to="#Visión">Descubre más</HashLink>
              </Button>

              <Button
                onClick={() => {setShowVideo(true)}}
                variant="ghost"
                size="lg"
                className="text-base px-4 md:px-6 space-x-2">
                <Icon className="size-5" icon="heroicons-outline:play" />
                <span>Ver Video</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="h-full w-[150%] sm:w-full mx-auto max-w-4xl md:max-w-5xl">
          <BrowserWindowComponent
            darkMode={true}
            image={Screenshot}
            darkImage={ScreenshotDark}
            alt="Hero Background"
            style={{ transform: transformValue }}
          />
          {showVideo && (
            <div className="absolute top-11 -bottom-12 rounded-b-3xl overflow-clip inset-x-0 z-50 flex justify-center items-center bg-black bg-opacity-90">
              <iframe
                className="w-full h-full"
                src="https://www.youtube.com/embed/1zolWTUEBHY?si=WlrrnbHBYe1k2vLr&amp;controls=0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen></iframe>
              <Button
                onClick={() => setShowVideo(false)}
                variant="ghost"
                className="absolute top-4 right-4">
                <Icon className="size-5" icon="heroicons-outline:x" />
              </Button>
            </div>
          )
          }
        </div>
      </div>
      <svg
        width="0"
        height="0"
        viewBox="0 0 1440 867"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          <clipPath id="divider" clipPathUnits={"objectBoundingBox"}>
            <path
              d="M0,0.847 C0,0.847,0.34,1,1,0.913 L1,0 L0,0 L0,0.847"
              fill="black"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
});

export default Hero;