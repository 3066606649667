import { ReactComponent as MainIcon } from "assets/page-header-items/main-item-yellow.svg";
import { ImageFrame } from "components/ui/ImageFrame";

import FrameImg from "assets/img/front/showcase_1.jpeg";
import TeamImg from "assets/img/our-team.webp";
import Contacts from "components/sections/Contacts";
import LogoLasNaves from "assets/img/partners/logo-las-naves.webp";
import LogoAyuntamiento from "assets/img/partners/logo-ayuntamiento-valencia.svg";
import LogoTheRoot from "assets/img/partners/logo-the-root.webp";
import LogoUnescoValencia from "assets/img/partners/logo-unesco.webp";
import LogoTribunalLasAguas from "assets/img/partners/logo-tribunal-las-aguas.webp";
import AnimatedTabs, { type TabsItem} from "components/molecules/AnimatedTabs";
// import OurTeam from "components/sections/OurTeam";
import OurTeamCompact from "components/sections/OurTeamCompact";

const AboutUs = () => {

// const tabsItems: TabsItem[] = [
//   {
//     icon: LogoLasNaves,
//     key: "lasnaves",
//     name: "Las Naves",
//     link: "https://www.lasnaves.com",
//     content:
//       "Hortatech fue creado dentro de Col·lab, la aceleradora pública de Las Naves, el centro de innovación del Ayuntamiento de Valencia. Esta colaboración nos proporciona acceso a recursos, experiencia y redes que mejoran el impacto y alcance del proyecto.",
//   },
//   {
//     icon: LogoAyuntamiento,
//     key: "ayuntamiento",
//     name: "Ayuntamient de València",
//     link: "https://valenciamediterraneo.es",
//     content:
//       "El “Tribunal de las Aguas de la Vega de Valencia”, más conocido por su denominación abreviada de “Tribunal de las Aguas” es, sin duda alguna, la más antigua de las instituciones de justicia existentes en Europa. Institución a la que vamos a ofrecer nuestro producto disruptivo e innovador para modernizar la gestión del agua de riego y ser una institución pionera en el mundo, aunando impactos ambientales, económicos y sociales. De esta forma podrá tener un control mas profundo y preciso de la gestión del consumo del agua de riego cara a aumentar el ahorro y optimizar las infraestructuras históricas.",
//   },
//   {
//     icon: LogoSIPAM,
//     key: "sipam",
//     name: "Sistema de Información de Patrimonio Agrícola Mundial",
//     link: "https://www.fao.org/giahs/es/",
//     content:
//       "El Sistema de Información de Patrimonio Agrícola Mundial (SIPAM) es una iniciativa de la Organización de las Naciones Unidas para la Alimentación y la Agricultura (FAO) que tiene como objetivo promover y proteger el patrimonio agrícola mundial. SIPAM trabaja en estrecha colaboración con comunidades agrícolas, expertos y organizaciones internacionales para identificar, documentar y conservar las prácticas agrícolas tradicionales y sostenibles que son fundamentales para la seguridad alimentaria y la preservación de la biodiversidad. Hortatech se enorgullece de ser parte de SIPAM y contribuir a la preservación de la huerta de Valencia y sus técnicas agrícolas tradicionales.",
//   },
//   {
//     icon: LogoMissionsValencia2030,
//     key: "missions",
//     name: "Missions Valencia 2030",
//     link: "https://www.missionsvalencia.eu/?lang=en",
//     content:
//       "UNESCO Valencia Centro del Mediterráneo es una institución asociada a la Organización de las Naciones Unidas para la Educación, la ciencia y la Cultura. Responsable por la inclusión del Tribunal de las Aguas en la Lista Representativa del Patrimonio Cultural Inmaterial de la Humanidad.",
//   },
// ];
const tabsItems: TabsItem[] = [
  {
    icon: LogoLasNaves,
    key: "lasnaves",
    name: "Las Naves",
    link: "https://www.lasnaves.com/proyectos/horta-tech/?lang=es",
    content:
      "Las Naves es el centro de innovación social y urbana de la ciudad de València. Una entidad pública que depende de la Delegación de Innovación y Gestión del Conocimiento del Ayuntamiento de València y que promueve la innovación urbana y social poniendo a las personas en el centro de sus acciones.",
  },
  {
    icon: LogoAyuntamiento,
    key: "ayuntamiento",
    name: "Ayuntamient de València",
    link: "https://www.valencia.es",
    content:
      "El Ayuntamiento de Valencia ha cofinanciado el desarrollo del proyecto Hortatech a través de las Concejalías de Innovación y Agricultura. Esta colaboración ha permitido impulsar soluciones tecnológicas innovadoras para la gestión eficiente del agua en la Huerta de Valencia, promoviendo la sostenibilidad y mejorando la vida de los agricultores locales.",
  },
  {
    icon: LogoUnescoValencia,
    key: "unesco",
    name: "UNESCO Valencia",
    link: "https://valenciamediterraneo.es/",
    content:
      "UNESCO Valencia Centro del Mediterráneo es una institución asociada a la Organización de las Naciones Unidas para la Educación, la ciencia y la Cultura. Responsable por la inclusión del Tribunal de las Aguas en la Lista Representativa del Patrimonio Cultural Inmaterial de la Humanidad.",
  },
  {
    icon: LogoTribunalLasAguas,
    key: "tribunal",
    name: "Tribunal de las Aguas",
    link: "https://www.tribunaldelasaguas.org",
    content:
      "El “Tribunal de las Aguas de la Vega de Valencia” es, sin duda alguna, la más antigua de las instituciones de justicia del agua existentes en Europa. Institución colaboradora que en un futuro incorporara nuestro producto disruptivo e innovador para modernizar la gestión del agua de riego , aunando impactos ambientales, económicos y sociales. De esta forma podrá tener un control mas profundo y preciso de la gestión del consumo del agua de riego cara a aumentar el ahorro y optimizar las infraestructuras históricas.",
  },
  {
    icon: LogoTheRoot,
    key: "theroot",
    name: "We The Root",
    link: "https://wetheroot.com",
    content:
      "We the Root (WTR) es una consultora especializada en innovación tecnológica y sostenibilidad, con un enfoque en la participación comunitaria. En el proyecto Hortatech, ha liderado la fase de escucha activa con los agricultores, adaptando la tecnología a las necesidades reales del sector agrícola. WTR ha contribuido al desarrollo de una aplicación de riego que facilita la gestión eficiente de los turnos de riego, promoviendo la adopción de soluciones innovadoras en la Huerta de Valencia. Su enfoque combina tecnología y conocimiento tradicional para mejorar la eficiencia hídrica.",
  },
];
  return (
    <div className="flex flex-col w-full">
      <section className="h-screen w-screen  overflow-x-clip flex  flex-col md:flex-row relative  py-20">
        <div className="w-full justify-start md:justify-center  flex h-full gap-y-10 flex-col p-4 md:pr-96 xl:pr-64 2xl:pr-32">
          <div className="flex-col-reverse justify-start items-start gap-y-2 flex">
            <h1 className="">
              Nuestra {""}
              <span className="relative inline-flex z-0 group">
                Motivación
                <div className="absolute group-hover:h-4 transition-all -z-10 w-full h-3 bottom-2 bg-yellow-300 dark:bg-yellow-600" />
              </span>
            </h1>
            <h2 className=" text-yellow-600 text-xl font-bold lg:text-2xl uppercase leading-7">
              Sobre nosotros
            </h2>
          </div>
          <ul className="list-inside text-lg list-disc grid grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-4 md:gap-6">
            <li className="flex flex-col">
              <span className="text-base">
                Un mundo sostenible y equitativo para las generaciones futuras
              </span>
            </li>
            <li className="flex flex-col">
              <span className="text-base">
                Preservar el patrimonio cultural y natural de la Huerta del
                mundo
              </span>
            </li>
            <li className="flex flex-col">
              <span className="text-base">
                Combatear el cambio climático y la escasez de agua en la región
              </span>
            </li>
          </ul>
        </div>

        <MainIcon className="w-full absolute h-fit transition-all bottom-40 -right-2 md:bottom-1/2 md:translate-y-1/2 md:right-0  aspect-square ml-auto max-w-40 md:max-w-96 " />
        <span className="w-full h-auto right-0  flex justify-center bottom-0 absolute">
          {/* <BottomDivider className="w-full max-h-48 md:max-h-full transition-[max-height] min-w-max h-full" /> */}

          <svg
            className="w-full mamax-h-48 md:max-h-full transition-[max-height] min-w-max h-full"
            width="100%"
            height="100%"
            viewBox="0 0 721 232"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g>
              <path
                d="M220.479,231.481l-220.185,-0l-0,-24.77c27.169,-19.338 60.391,-30.716 96.25,-30.716c49.226,0 93.483,21.441 123.935,55.486Zm465.459,-0c-5.26,-13.654 -8.144,-28.483 -8.144,-43.981c-0,-37.045 16.479,-70.271 42.5,-92.742l-0,136.723l-34.356,-0Zm-286.121,-16.515c38.186,-17.629 80.696,-27.466 125.486,-27.466c57.274,-0 110.821,16.085 156.366,43.981l-447.893,-0c18.994,-42.324 66.458,-65.864 112.697,-53.474c22.397,6.001 40.827,19.445 53.344,36.959Z"
                className="fill-yellow-500 dark:fill-yellow-500"
              />
              <path
                d="M101.962,231.481l-101.668,-0l-0,-37.69c38.543,0.855 73.869,14.855 101.668,37.69Zm356.034,-0c22.219,-31.153 58.653,-51.481 99.798,-51.481c41.144,-0 77.578,20.328 99.797,51.481l-199.595,-0Zm-313.826,-0c22.841,-30.835 63.015,-46.247 102.303,-35.72c21.865,5.859 39.95,18.812 52.443,35.72l-154.746,-0Z"
                className="fill-yellow-300 dark:fill-yellow-700"
              />
              <path
                d="M513.896,231.481c41.052,-49.749 103.174,-81.481 172.648,-81.481c11.471,-0 22.741,0.865 33.75,2.533l-0,78.948l-206.398,-0Zm-228.088,-0c23.161,-26.922 57.472,-43.981 95.736,-43.981c38.264,-0 72.575,17.059 95.735,43.981l-191.471,-0Zm-247.859,-0c17.545,-23.646 45.668,-38.981 77.345,-38.981c31.676,-0 59.8,15.335 77.344,38.981l-154.689,-0Z"
                className="fill-yellow-100 dark:fill-yellow-800"
              />
            </g>
          </svg>
        </span>
      </section>
      <section className="bg-yellow-100 dark:bg-yellow-800 -translate-y-0.5">
        <div className="items-center ">
          <h2>Nuestro Equipo</h2>
          
          {/* <ImageFrame className=" w-full max-h-96" src={TeamImg} alt="Frame" /> */}
          <p>
            Nuestro equipo dedicado reúne una gran cantidad de conocimientos y
            experiencia, trabajando en colaboración para impulsar las soluciones
            y estrategias innovadoras que Hortatech encarna. A medida que
            continuamos creciendo y expandiendo nuestro proyecto, estamos
            abiertos a asociaciones y colaboraciones con organizaciones e
            individuos que compartan nuestra visión de gestión sostenible del
            agua y preservación del patrimonio en la Huerta de Valencia.
          </p>
        </div>
      </section>
      <section>
        <div>
          <h2>Colaboración con Empresas y Entidades Líderes</h2>
          <p>
            Hortatech se enorgullece de colaborar con varias empresas y
            entidades prominentes que comparten nuestro compromiso con la
            innovación y la sostenibilidad. Nuestras asociaciones incluyen:
          </p>
          <AnimatedTabs items={tabsItems} />
        </div>
      </section>
      <section>
        <div>
          <h2>Miembros del equipo y su experiencia</h2>
          <p>
            En Hortatech, nuestro equipo está formado por profesionales
            altamente capacitados con diversos antecedentes y experiencia en los
            campos de gestión del agua, preservación del patrimonio, tecnología
            y sostenibilidad. Algunos miembros clave del equipo incluyen:
          </p>
          <OurTeamCompact />
        </div>
      </section>
      <section>
        <div className="">
        <h2 className="text-center">Antecedentes e historia de Hortatech</h2>
          
          <ImageFrame className=" w-full max-h-96" src={FrameImg} alt="Frame" />
          {/* Mail: hola@hortatech.es */}
          <p>
            Hortatech fue fundado en 2022 con la visión de abordar los desafíos
            urgentes de la gestión del agua y la preservación del patrimonio en
            la Huerta de Valencia. El proyecto surgió como respuesta al
            creciente impacto del cambio climático, que amenaza la
            sostenibilidad de los recursos hídricos de la región y pone en
            riesgo el patrimonio cultural y natural.
          </p>
         
        </div>
      </section>

      <section>
        <Contacts />
      </section>
    </div>
  );
};

export default AboutUs;
